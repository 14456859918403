.user-actions {
  align-items: center;
  display: flex;

  &__container {
    align-items: center;
    background-color: $lightest-blue;
    border-radius: 2rem;
    display: flex;
    justify-content: space-between;
    padding: 0.1rem $base-small-spacing;
  }
}