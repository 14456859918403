.score-card {
  background: white;
  position: sticky;
  bottom: -1.57rem;
  margin-bottom: -30rem;
  z-index: 70;
  margin-left:-1.5rem;
    
  input {
    width: 3.4rem;
    height: 2rem;
  }

  textarea {
    min-height: 5rem !important;
  }

  &__read-only {
    background: white;
  }
    
  &__read-only-score {
    margin-top: $base-small-spacing;
    margin-right: $base-small-spacing;
  }
        
  &__header {
    display: flex;
    background: $light-blue;
    height: 3.5rem;
  }

  &__read-only-header {
    display: flex;
    background: $light-blue;
    height:  3.5rem;
    justify-content: space-between;
  }

  &__read-only-content {
    padding: $base-spacing;
  }

  &__title {
    flex: 6;
    color: $primary-color;
    font-size: $base-font-size;
    font-weight: $weight-semibold;
    padding: 0.75rem !important;

    h4 {
      margin: 0;
    }
  }

  &__read-only-title {
    color: $primary-color;
    font-size: $base-font-size;
    font-weight: $weight-semibold;
    margin-top: $base-small-spacing;
    margin-left: $base-small-spacing;

    h4 {
      margin-bottom:0rem;
    }
  }

  &__score {
    text-align: center; 
  }

  &__score-field {
    margin-top: 1.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  &__error-text-hidden {
    width: 12.5rem;
    margin-top: $base-small-spacing;
    visibility: hidden;
    color: $red;
    font-weight: $weight-bold;
    margin-bottom: $base-small-spacing;     
  }

  &__error-text-visible {
    width: 12.5rem;
    margin-top: $base-small-spacing;
    visibility: visible;
    color: $red;
    font-weight: $weight-bold;
    margin-bottom: $base-small-spacing;
  }

  section {
    display: flex;
    flex-direction: row;
    align-items: top;
    padding: $base-spacing;

    input-field {
      margin-bottom: 0;
    } 
  }
    
  &__spacer {
    height: 12rem;
  }

  &__nopadding {
    padding: 0px !important;
    visibility: hidden;
    height: 0;
  }

  &__footer {
    display: flex;
    padding: $base-small-spacing;
    justify-content: space-between;
    color: white;
    align-items: center;
    background: $royal;
    font-weight: $weight-regular;
  }

  &__footer-title {
    margin: 0px;
    color: white;
    font-weight: 200 !important;
  }
      

  &__small {
    width: 100%;
  }

  .score-card-header {
    display: flex;
    background: $light-blue;
    align-items: center;
    height: 3rem; 
  }

  @media only screen and (max-width: 1000px) {
    .score-card-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: $light-blue;
      min-height: 10rem;
      padding-left: 1rem;
      padding-top: 1rem;
    }

    &__title {
      color: $primary-color;
      font-size: $base-font-size;
      font-weight: $weight-semibold;
      order: 0;
      padding: 0px !important;
      flex: 0;
    }

    &__score-field {
      margin: 0;
      order:1;
    }
    
    input-field {
      margin-bottom: 0px;
    }

    textarea {
      max-height: 3rem;
    }

    &__score {
      text-align: center;  
    }

    section {
      padding: 0;
    }

    &__error-text-hidden {
      min-width: 12.5rem;
      visibility: hidden;
      color: $red;
      font-weight: $weight-semibold;
      margin-bottom: $base-small-spacing;
      order: 3;     
    }

    &__error-text-visible {
      width: 12.5rem;
      visibility: visible;
      color: $red;
      font-weight: $weight-semibold;
      margin-bottom: $base-small-spacing;
      order: 3;
    }

    input {
      margin-top: 0.5em;
      min-width: 6.25rem;
      flex: 1;
    }

    &__small {
      display: flex !important;
      flex-direction: column;
      padding: $base-extra-small-spacing;
    }
    
    .btn--small {
      display: none;
    }
 }
    
  @media print {
    &__read-only-header {
      box-shadow: inset 0 0 0 1000px $light-blue;
    }
  }
}