.icon {
  color: $medium-dark-gray;
  display: inline-block;
  line-height: 0;
  overflow: hidden;
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;

  .icon-notification {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    outline: 1px solid $base-background-color;
    background-color: $teal;
    border-radius: 50%;
    height: $action-list-item-indicator-size;
    width: $action-list-item-indicator-size;
  }

  svg {
    fill: currentColor;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: $base-transition;
  }

  &--block {
    display: block;
  }

  &--positive {
    color: $green;
  }

  &--negative {
    color: $red;
  }

  &--help {
    color: $primary-color;
  }

  &--warning {
    color: $color-warning;
  }

  &--success {
    color: $color-success;
  }

  &--error {
    color: $color-error;
  }

  &--info {
    color: $color-info;
  }

  &--white {
    color: white;
  }

  &--inline {
    height: .8rem;
    width: .8rem;
  }

  &--vertical-align {
    vertical-align: text-bottom;
  }

  &--jumbo {
    height: 4rem;
    width: 4rem;

    .icon-notification {
      height: $action-list-item-indicator-size * 2;
      width: $action-list-item-indicator-size * 2;
    }
  }

  &--double {
    height: 2rem;
    width: 2rem;

    .icon-notification {
      height: $action-list-item-indicator-size * 1.5;
      width: $action-list-item-indicator-size * 1.5;
    }
  }

  &--rotated {
    transform: rotate(-90deg);
  }

  &--rotated-clockwise {
    transform: rotate(90deg);
  }

  &--right:not(.action-button) {
    padding: 0 $base-small-spacing;
    margin-left: 0.25rem;
  }

  &--left:not(.action-button) {
    padding: 0 $base-small-spacing;
    margin-right: 0.25rem;
  }
}

.icon--disclosure-down {
  position: absolute;
  top: 0;
  right: 0;
  margin: .75rem;
  padding: 0;
  transition: $base-transition;
  cursor: pointer;
  width: 24px;
  height: 24px;
  overflow: hidden;

  svg {
    fill: $medium-gray;

    &:hover {
      fill: $medium-dark-gray;
    }
  }

  &.is-open {
    transform: rotate(-90deg);
  }
}

