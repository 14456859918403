// Styles overridden
.v2-styles {
  hr {
    margin: 1rem 0;
  }

  h2, h3, h4 {
    color: $base-font-color;
    margin-bottom: 0;
    font-weight: $weight-bold;
  }

  .notifications-menu hr {
    margin: 0;
  }

  &.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &.card-title, .card-title {
    font-weight: $weight-semibold;
    color: $dark-gray;
    font-size: 0.9rem;
    margin: 0;

    &--margin {
      margin-bottom: 1.5rem;
    }
  }

  .po-select__selection-list-item {
    border-radius: 20px;
    color: $base-font-color;
    min-height: 0;
    padding: 0 0.5rem;
    background-color: $lightish-gray;

    .icon {
      color: $base-font-color;
    }
  }
}

// Use on components that contain action buttons
.v2-icon-button .action-button, .v2-icon-button.action-button {
  border: none;
  color: $medium-gray;
  min-width: 32px;
  padding: 0;
  background-color: white;

  icon {
    color: $medium-gray;

    &--only {
      border-radius: 50%;
    }
  }

  &--borderless {
    background-color: white;
  }
}

// New styles for v2
.subtitle {
  display: flex;
  align-items: center;
  color: $medium-dark-gray;
  flex-shrink: 0;

  &--small {
    font-size: 0.875rem;
  }

  &--alert {
    $color: $color-error;
  }

  *.icon {
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin-right: 0.3rem;
  }
}