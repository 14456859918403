ul {
  list-style-type: disc;
  margin: 0 0 $base-spacing 0;
  padding: 0 0 0 $base-spacing;
}

ol {
  list-style-type: decimal;
  margin: 0 0 $base-spacing 0;
  padding: 0 0 0 $base-spacing;

  ol {
    list-style-type: lower-alpha;

    ol {
      list-style-type: lower-roman;
    }
  }

  &.required::after {
    content: "*";
    color: $color-required;
    padding-left: 0.25rem;
    font-size: $base-font-size;
  }
}

.icon-list {
  list-style: none;
  
  &__item {
    margin-bottom: $base-small-spacing;
    padding-left: $base-spacing;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      display: block;
      margin-right: calc($base-extra-small-spacing / 2);
      position: absolute;
      left: 0;
      top: 0;
      width: 0.85rem;
    }

    &__link {
      display: flex;
      text-decoration: underline;

      .icon {
        margin-right: $base-extra-small-spacing;
      }
    }

    &__button {
      position: absolute;
      left: -0.5rem;

      .icon {
        margin-right: 0;
        position: relative;
      }
    }
  }
}

dl {
  margin-bottom: $base-small-spacing;

  dt {
    font-weight: $weight-semibold;
    margin: 0;
  }

  dd {
    margin: 0 0 $base-extra-small-spacing 0;
  }
}

.dl-horizontal {
  font-size: $base-font-size;
  margin: 0 0 $base-small-spacing 0;

  dt {
    display: inline-block;
    color: $primary-color;
    margin: $base-extra-small-spacing 0.25rem 0 0;

    &:after {
      content: ":";
    }

    &:first-child {
      margin-top: 0;
    }
  }

  dd {
    display: inline;
    margin: 0;

    &:after {
      content: "\a";
      white-space: pre;
    }
  }

  .no-after {
    &:after {
      content: none;
    }
  }
}

.list {
  &--plain,
  &--comma-separated {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &--comma-separated {
    display: inline;

    li {
      display: inline;

      &:after {
        content: ", ";
      }

      &:last-child:after {
        content: "";
      }
    }
  }

  &--circle {
    list-style-type: circle;
  }
}

.unordered-list {
  li {
    margin-bottom: $base-small-spacing;
    line-height: 1.2;
  }
}
