.event-card {
  background: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  border-left-width: 5px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  padding: 1rem;
}


.status-card {
  background: $base-background-color;
  border: $base-border;
  border-top-width: 5px;
  border-radius: $base-border-radius;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  width: auto;
  min-height: 8rem;
  margin-bottom: $base-spacing;

  &__icon {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 50%;
    margin-right: $base-spacing;

    .icon {
      color: white;
    }
  }

  &__trigger {
    align-self: flex-start;
    background-color: transparent;
    border-radius: $base-border-radius;
    border: $base-border;
    cursor: pointer;
    line-height: 0;
    padding: $base-tiny-spacing;
    margin-left: $base-small-spacing;

    .icon {
      transition: $base-transition;
    }
  }

  &__body {
    flex: 1;
    min-width: 0;
  }

  &__content > p:last-child {
    margin-bottom: 0;
  }

  &[aria-expanded="false"] {
    .status-card__content > * {
      display: none;

      &:first-child {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
      }
    }
  }

  &[aria-expanded="true"] {
    .status-card__trigger .icon {
      transform: rotate(90deg);
    }
  }

  &--small {
    min-height: 4rem;
  }

  &--smaller {
    min-height: 2rem;
    padding: $base-tiny-spacing $base-small-spacing;
  }

  &--success {
    border-top-color: $color-success;

    .status-card__icon {
      background-color: $color-success;
    }
  }

  &--error {
    border-top-color: $color-error;

    .status-card__icon {
      background-color: $color-error;
    }
  }

  &--warning {
    border-top-color: $color-warning;

    .status-card__icon {
      background-color: $color-warning;
    }
  }

  &--info {
    border-top-color: $color-info;

    .status-card__icon {
      background-color: $color-info;
    }
  }
}

inline-alert {
  p {
    margin: 0;
  }
}