.text-icon {
  display:grid;
  grid-template-columns: 70% 20%;
  grid-template-rows: auto;
  grid-column-gap: 10%;
  align-items: center;
}

.text-element {
  grid-column-start: 1;
  grid-column-end: 2;
  justify-self: end;
}

.icon-element {
  grid-column-start: 2;
  grid-column-end: 3;
  justify-self: end;
}