.js-hidden {
  display: none;
}

.skip-link {
  -webkit-appearance: none;
  border: 0;
  display: block;
  height: 2rem;
  margin: auto;
  overflow: hidden;
  padding: 0.25rem;
  position: absolute;
  left: 0;
  right: 0;
  transition: transform $base-duration $base-timing;
  transform: translateY(-2rem);
  width: 10rem;

  &:focus {
    transform: translateY(0);
    z-index: 99;
  }
}

.screen-reader-text {
  display: block;
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0;
}

$none: ("none", 0);
$tiny: ("xxs", $base-tiny-spacing);
$xsmall: ("xs", $base-extra-small-spacing);
$small: ("sm", $base-small-spacing);
$regular: ("reg", $base-spacing);

$allSizes: ($none, $tiny, $xsmall, $small, $regular);
$directions: ("top", "right", "bottom", "left");

//---------------------------
// FIXME: Delete these eventually
//---------------------------
.margin {
  margin: $base-spacing;
}

.mb-l {
  margin-bottom: $base-spacing;
}

.mb-s {
  margin-bottom: $base-small-spacing;
}

.mb-xs {
  margin-bottom: $base-extra-small-spacing;
}

.ml-s {
  margin-left: $base-small-spacing;
}

.mr-s {
  margin-right: $base-small-spacing;
}

.ml-l {
  margin-left: $base-spacing;
}

.mr-l {
  margin-right: $base-spacing;
}

.mt-s {
  margin-top: $base-small-spacing;
}

.mt-l {
  margin-top: $base-spacing;
}

.mt-xl {
  margin-top: $base-tiny-spacing;
}

.padding {
  padding: $base-spacing;

  &--xs {
    padding: $base-extra-small-spacing;
  }

  &--sm {
    padding: $base-small-spacing;
  }
}

.width-50 {
  min-width: 50rem;
  max-width: 50rem;;
}

//---------------------------
// Columns and spacing
//---------------------------

.flex {
  display: flex;

  &--column {
    flex-direction: column;
  }

  @for $i from 1 through 10 {
    .grow-#{$i} {
      flex-grow: $i;
      flex-shrink: 0;
    }
  }

  &--justify-content {
    &-between {
      justify-content: space-between;
    }
    &-end {
      justify-content: flex-end;
    }
    &-center {
      justify-content: center;
    }
  }

  &--align-items {
    &-center {
      align-items: center;
    }
    &-start {
      align-items: flex-start;
    }
    &-end {
      align-items: flex-end;
    }
  }

  >.flex-auto {
    flex: 1 auto;
  }
}

.inline-flex {
  display: inline-flex;
  &--center {
    vertical-align: middle;
  }
}

@for $i from 1 through 10 {
  .flex-grow-#{$i} {
    flex-grow: $i;
  }
}

.align-self {
  &-start {
    align-self: flex-start;
  }
  &-center {
    align-self: center;
  }
}

.flex-wrap {
  flex-wrap: wrap;
  row-gap: 10px;
}

@media screen {
  @supports (display: grid) {
    .columns {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: $base-spacing;
      margin-bottom: 0;

      &--gap-small {
        grid-gap: $base-small-spacing;
      }

      &--gap-none {
        grid-gap: 0rem;
      }
    }
  }
}

@media screen and (min-width: 680px) {
  @supports (display: grid) {
    $column-numbers: 5;

    @for $i from 2 through $column-numbers {
      .columns--#{$i} {
        grid-template-columns: repeat(#{$i}, 1fr);
      }
    }

    @for $i from 1 through ($column-numbers + 1) {
      .column-start-#{$i} {
        grid-column-start: #{$i};
      }
      .column-end-#{$i} {
        grid-column-end: #{$i};
      }
    }

    .columns-divider {
      position: relative;

      &::after {
        content: "";
        width: 2px;
        background: $light-gray;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.columns {
  margin-bottom: $base-spacing;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.bold {
  font-weight: $weight-bold;
}

.weight-regular {
  font-weight: $weight-regular;
}

.spaced {
  margin-bottom: 1rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.pre-wrap {
  white-space: pre-wrap;
}

.pre-line {
  white-space: pre-line;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  filter: grayscale(100%);
}

.center {
  margin: auto !important;
}

.inline {
  display: inline-block;
}

.flex {
  &--apart {
    justify-content: space-between;
  }
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.scroll-disabled {
  overflow: hidden;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;

  @for $i from 1 through 3 {
    &--clamp-#{$i} {
      -webkit-line-clamp: $i;
    }
  }
}

// This is a cool way to show an "empty" template if filtering is done directly in the DOM instead of the array itself.
// Add this as the last element on the same level of your *ngFor and it will show up if the *ngFor does not render anything.
.show-if-empty:not(:first-child) {
  display: none;
}

// Putting these last so that margin and padding helpers take precedence over other classes
@each $sizeName, $sizeValue in $allSizes {
  @if $sizeName == reg {
    .margin {
      margin: $sizeValue;
    }

    .padding {
      padding: $sizeValue;
    }
  } @else {
    .margin-#{$sizeName} {
      margin: $sizeValue;
    }

    .padding-#{$sizeName} {
      padding: $sizeValue;
    }
  }

  @each $direction in $directions {
    @if $sizeName == reg {
      .margin-#{$direction} {
        margin-#{$direction}: $sizeValue !important;
      }

      .padding-#{$direction} {
        padding-#{$direction}: $sizeValue;
      }
    } @else {
      .margin-#{$direction}-#{$sizeName} {
        margin-#{$direction}: $sizeValue !important;
      }

      .padding-#{$direction}-#{$sizeName} {
        padding-#{$direction}: $sizeValue;
      }
    }
  }
}

$alignment: ("left", "center", "right");

@each $direction in $alignment {
  .text-#{$direction},
  .align-#{$direction} {
    text-align: #{$direction};
  }
}

.margin-align-center {
  margin: 0 auto;
}

@each $direction in $directions {
  .base-border-#{$direction} {
    border-#{$direction}: $base-border !important;
  }
}

.highlight-background {
  background-color: $lightest-blue;
}

.text-small {
  font-size: 0.9rem;
}