plan-timeline {
  display: flex;
  margin-bottom: 1.5rem;
  overflow: hidden;

  .year-content {
    font-size: 0.875rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
  }

  .year-header {
    font-weight: $weight-semibold;
    flex-grow: 1;
    padding-left: 0.5rem;
    white-space: nowrap;
  }

  .quarter-header {
    flex-grow: 1;
    padding-left: 0.5rem;
    color: $medium-gray;
  }

  .quarter-content {
    border: 0 solid $medium-gray;
    border-right-width: 1px;

    &.first-year {
      border-left-width: 1px;
    }
  }

  .quarter-cells {
    background-color: $lightest-gray;
    display: flex;
    min-height: 70px;

    .report-icon {
      display: flex;
      justify-content: flex-end;
      z-index: 60;
      position: absolute;
      right: 0;

      icon {
        color: $primary-color;
      }

      &.final-report {
        icon {
          color: $bright-blue;
        }
        justify-content: start;
        left: 0;
      }
    }
  }

  .quarter-cell {
    flex-basis: 25%;
    max-width: 25%;
    border-right: 1px solid $light-gray;
    border-top: 10px solid $base-background-color;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem 0;
    row-gap: 0.25rem;

    &:last-child {
      border-right: none;
    }
  }

  .plan-bar {
    font-weight: $weight-semibold;
    font-size: 0.875rem;
    overflow: hidden;
    z-index: 50;
    color: $base-background-color;
    display: flex;
    margin-right: -1px;

    &--start {
      border-radius: 20px 0 0 20px;
      z-index: 70;

      .plan-active {
        padding-left: 1rem;
      }

      &.plan-bar--end {
        border-radius: 20px;
      }
    }
    &--end {
      border-radius: 0 20px 20px 0;
      margin-right: 0;
    }

    &--funding-source {
      .report-icon icon {
        color: $base-background-color;
      }
    }

    &.plan-bar--end {
      .report-icon icon {
        margin-right: 3px;
      }
    }

    .plan-active {
      color: $base-background-color;
      background-color: $primary-color;
      outline-color: $primary-color;
      width: 100%;
      opacity: 1;
      outline: 1px solid;
      text-wrap: nowrap;

      &.plan-active--secondary {
        color: $base-font-color;
        background-color: $light-gray;
        outline-color: $light-gray;
      }

      .plan-label {
        position: absolute;
      }
    }
  }

  .empty-state {
    flex-grow: 1;
  }
}
