.status-message {
  display: flex;
  align-items: center;
  min-height: 2rem;
  justify-content: flex-end;

  .icon {
    display: flex;
    align-items: center;
  }

  &--align-left {
    justify-content: flex-start;
  }

  &__message {
    margin: 0;
    display: inline-block;

    &__fail {
      color: $color-error;
    }

    .loader-spinner-inline {
      @include spinner-color($primary-color);
      margin-left: $base-extra-small-spacing;
      top: .25rem;
    }
  }
}
