app-action-items {
  .card {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  font-size: 0.875rem;

  .action-item:first-child {
    border-top: none;
  }
}

.action-items-filter {

  ul {
    display: flex;
    border-radius: 4px 0 0 4px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 1;

    > li {
      flex: 1;
      text-align: center;

      &:first-child {
        .action-items-filter__label {
          border-radius: 4px 0 0 4px;
        }
      }

      &:not(:first-child) {
        .action-items-filter__label {
          border-left: 0;
        }
      }

      &:last-child {
        .action-items-filter__label {
          border-radius: 0 4px 4px 0;
        }
      }

      .action-items-filter__label-checked {
        background-color: $primary-color;
        border-color: $primary-color;
        color: white;
      }
    }
  }

  &__input {
    display: none !important;

    &:checked + .action-items-filter__label {
      background-color: $primary-color;
      border-color: $primary-color;
      color: white;
    }
  }

  &__label {
    border: 1px solid $base-border-color;
    font-weight: $weight-regular;
    width: 100%;
    margin: 0;
    cursor: pointer;
    color: $base-font-color;
    padding-left: $base-spacing;
    padding-right: $base-spacing;
  }
}

.action-items-container {
  position: relative;
  height: 100%;
}

.action-items-list {
  overflow-y: auto;
  flex-grow: 1;
  height: 100%;
}

.action-items-empty {
  margin-top: 4rem;
  text-align: center;

  &__header {
    font-weight: 600;
    font-size: 1.1rem;
    margin-top: 0.9rem;
  }

  &__label {
    margin-top: 0.3rem;
  }
}

.action-item {
  border-top: $base-border;
  padding: 10px 0;
  height: 66px;

  .visibility-status {
    position: relative;
    margin-right: 3px;
    color: transparent;
    min-height: 18px;
    min-width: 18px;
    height: 18px;
    width: 18px;
  }

  &--hidden {
    background-color: $lighter-gray;

    icon.visibility-status {
      color: $medium-gray;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    min-width: 0;
    width: 100%;
  }

  &__title-container {
    max-width: 60%;
  }

  &:last-child {
    border-bottom: $base-border;
  }

  &__action {
    font-weight: $weight-semibold;
  }

  &__project {
    text-decoration: underline;
  }

  &__sub-title {
    color: $medium-dark-gray;
  }

  &__due-date {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 1rem;

    &--past-due {
      color: $color-error;

      *.icon {
        color: $color-error;
        height: 16px;
        width: 16px;
        min-width: 16px;
        margin-right: 0.3rem;
      }
    }
  }

  .kabob-button {
    margin-right: 1px;
    margin-left: 0.5rem;
    width: 40px;
    height: 40px;

    icon {
      min-height: 28px;
      min-width: 28px;
    }
  }
}

.busy-spinner-overlay--alt.transparent-spinner {
  background: rgba(255, 255, 255, 0.75);
  z-index: 400000;
  height: initial;
  top: initial;
  bottom: 0;
}

.cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

.show-hidden-toggle {
  .toggle-label {
    font-weight: $weight-semibold;
  }
}