img,
picture {
  margin: 0;
  max-width: 100%;
}

.image-wrapper {
  height: 100%;
  overflow: hidden;
  padding: 0 2rem 2rem;
  width: 100%;
  min-width: 50px;
  max-width: 450px;

  &--center {
    margin: auto;
  }

  img {
    height: auto;
    width: 100%;
  }
}