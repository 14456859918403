@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loader-spinner {
  display: inline-block;
  margin: 1rem;

  &__bar {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: .5rem solid transparentize(#fff, 0.8);
    border-top-color: #fff;
    animation: spin 0.75s infinite linear;
  }

  &__text {
    color: #fff;
  }
}

.loader-spinner-container {
  margin: 0 auto;
  padding: $base-spacing;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-spinner-inline {
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  transform: translateZ(0);
  animation: rotate 800ms infinite linear;
  width: 20px;
  height: 20px;

  &:after {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
}

.busy-spinner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 500000;
  opacity: 1;
  background: rgba(0,0,0,0.75);

  &--alt {
    background: #fff;

    .loader-spinner {

      &__logo {
        margin-bottom: .75rem;
        width: 8rem;
      }

      &__text {
        color: $action-color;
        text-align: center;
      }

      &__bar {
        border-color: rgba($action-color, .2);
        border-top-color: $action-color;
      }
    }
  }

  &--transparent {
    background-color: rgba(255, 255, 255, 0);
  }
}
