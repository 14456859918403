@mixin segmented-label {
  background: white;
  border: $base-border;
  border-width: 0 1px;
  color: $primary-color;
  cursor: pointer;
  display: block;
  font-weight: $weight-semibold;
  height: 100%;
  line-height: 2.5rem;
  margin: 0;
  padding: 0 $base-small-spacing;
  text-align: center;
  transition: background-color $base-duration $base-timing;

  &:focus {
    outline-offset: -2px;
  }

  &:hover {
    background: $lightest-gray;
  }
}

@mixin segmented-active {
  background: $primary-color;
  border: 1px solid $primary-color;
  border-width: 0 1px;
  color: white;
  text-decoration: underline;
  text-decoration-color: $gold;
  text-underline-position: under;

  &:hover {
    background: $primary-color;
  }
}

.segmented-control {
  background: $page-background-color;
  border-bottom: $base-border;
  box-shadow: $base-box-shadow-light;

  &__list {
    display: flex;
    width: 100%;
    padding: 0;
    height: 2.5rem;
    list-style-type: none;
    margin: 0 auto;
    max-width: 70rem;
  }

  &__item {
    margin: 0;
    padding: 0;
    position: relative;
    flex: 1 1 0;

    &:first-child {
      .segmented-control__anchor,
      .segmented-control__label {
        border-left: none;
        border-radius: $base-xlarge-border-radius 0 0 $base-xlarge-border-radius;
      }
    }

    &:last-of-type {
      .segmented-control__anchor,
      .segmented-control__label {
        border-right: none;
        border-radius: 0 $base-xlarge-border-radius $base-xlarge-border-radius 0;
      }
    }
  }

  .segmented-control__anchor {
    @include segmented-label;

    &--active {
      @include segmented-active;
    }

    &:focus {
      outline: $focus-outline-alt;
      outline-offset: $focus-outline-inset;
    }
  }

  .segmented-control__input {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    z-index: -1;

    &:focus {
      & + .segmented-control__label {
        outline: $focus-outline-alt;
        outline-offset: $focus-outline-inset;
      }
    }

    &:checked + .segmented-control__label {
      @include segmented-active;
    }
  }

  .segmented-control__label {
    @include segmented-label;
  }

  &--stand-alone {
    box-shadow: none;
    border-bottom: none;
    background: transparent;

    .segmented-control {
      &__list {
        border: $base-border;
        border-radius: $base-xlarge-border-radius;
        overflow: hidden;
      }
    }
  }
}

