.btn {
  display: flex;
  align-items: center;
  background-color: $btn-color;
  color: white;
  border: none;
  border-radius: $base-xlarge-border-radius;
  transition: $base-transition;
  white-space: nowrap;
  padding: 0 0.875rem;
  height: 2.5rem;
  font-size: $btn-font-size;
  line-height: $base-line-height;
  box-shadow: $btn-shadow-light;
  pointer-events: auto;
  float: left;

  .icon--arrow {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;

    svg {
      fill: $secondary-color;

      &:hover {
        fill: $secondary-color;
      }
    }
  }

  &:focus {
    color: white;

    &.secondary,
    &.text {
      color: $btn-color;
    }
  }

  &:hover {
    color: white;
    background-color: $btn-color-hover;
    box-shadow: none;
    transform: all;

    .icon--arrow {
      transition: all 300ms ease-in-out;
      transform: translateX(0.3rem);
    }
  }

  &:disabled {
    background: rgba(0, 0, 0, 0.05);
    border-color: $medium-gray;
    color: $medium-dark-gray;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      background: $lightest-gray;
      box-shadow: $btn-shadow-light;
    }
  }

  &:active {
    box-shadow: none;
  }

  &.inactive {
    opacity: 0.5;
    background-color: $medium-gray;
    box-shadow: none;
    pointer-events: none;
  }

  &.secondary {
    background-color: transparent;
    border: 1px solid $btn-color;
    box-shadow: none;
    color: $btn-color;

    &:hover {
      border: 1px solid $primary-color-lighter;
      color: $primary-color-lighter;
    }

    &:active {
      border: 1px solid $btn-color;
    }

    &.margin-top {
      margin-top: 0.75rem;
    }
  }

  &.text {
    color: $btn-color;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    display: table;
    height: auto;

    &:hover {
      color: $primary-color-lighter;
    }
  }

  &.center {
    margin: 0 auto;
    float: none;
    justify-content: center;
  }

  &.right {
    float: right;
  }

  &.margin-bottom {
    margin-bottom: $base-spacing;
  }
}

.btn-section {
  align-items: center;
  display: flex;
  margin: 1rem 0;
  padding: 0;

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }
}

.action-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: $base-xlarge-border-radius;
  transition: background-color $base-duration $base-timing,
    box-shadow $base-duration $base-timing;
  padding: 0 $base-small-spacing;
  height: 2.5rem;
  min-width: 2.5rem;
  font-size: $btn-font-size;
  pointer-events: auto;
  cursor: pointer;
  border: none;
  vertical-align: bottom;
  line-height: 1.2rem;
  overflow: hidden;
  white-space: nowrap;

  .loader-spinner-inline {
    display: none;
    vertical-align: middle;
  }

  &.loading {
    pointer-events: none;

    .loader-spinner-inline {
      display: inline-block;
      margin-left: $base-small-spacing;
    }

    .icon {
      display: none;
    }
  }

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;

    .icon {
      color: $medium-dark-gray;
    }
  }

  &--primary {
    background-color: $primary-color;
    color: white;

    .loader-spinner-inline {
      @include spinner-color(white);
    }

    .icon {
      color: white;
    }

    &:hover,
    &:focus {
      background-color: $btn-color-hover;
      color: white;
    }
  }

  &--invisible {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: none;
    cursor: pointer;
    margin-right: 0.5rem;
  }

  &--secondary {
    background-color: white;
    border: 1px solid $primary-color;
    color: $primary-color;

    .loader-spinner-inline {
      @include spinner-color($primary-color);
    }

    .icon {
      color: $primary-color;
    }

    &:hover,
    &:focus {
      background-color: white;
      color: $primary-color;
    }

    &:disabled {
      background: transparent;
      border-color: $medium-gray;
      color: $medium-gray;

      &:hover,
      &:focus {
        background: transparent;
        box-shadow: none;
      }

      .icon {
        color: $medium-gray;
      }
    }
  }

  &--tertiary {
    background-color: white;
    border: 1px solid $light-gray;
    color: $base-font-color;

    .loader-spinner-inline {
      @include spinner-color($base-font-color);
    }

    &:hover,
    &:focus {
      background-color: white;
      border: 1px solid $light-gray;
      color: $base-font-color;
    }
  }

  &--warning {
    border: 1px solid $color-error;
    color: $color-error;

    .icon {
      color: $color-error;
    }

    &:hover,
    &:focus {
      border: 1px solid $color-error;
      color: $color-error;
    }
  }

  &--primary,
  &--tertiary {
    &:disabled {
      background: darken($lightest-gray, 5);
      border-color: transparent;
      color: $medium-gray;

      &:hover,
      &:focus {
        background: darken($lightest-gray, 5);
        box-shadow: none;
      }

      .icon {
        color: $medium-gray;
      }
    }
  }

  &:hover {
    box-shadow: $btn-shadow-light;
  }

  &.close-button {
    min-width: 1rem;
    padding: 0 0.5rem;
  }

  .icon {
    $icon-size: 16px;
    vertical-align: middle;
    min-height: $icon-size;
    min-width: $icon-size;
    height: $icon-size;
    width: $icon-size;
    padding: 0;

    &.icon--small {
      $icon-size: 10px;
      min-height: $icon-size;
      min-width: $icon-size;
      height: $icon-size;
      width: $icon-size;
    }
  }

  &.icon--only {
    padding: 0;

    .loader-spinner-inline {
      margin: 0;
    }
  }

  &.icon--right {
    padding: 0 $base-small-spacing;

    .icon {
      margin-left: 0.25rem;
    }
  }

  &.icon--left {
    padding: 0 $base-small-spacing;

    .icon {
      margin-right: 0.25rem;
    }
  }

  &--small {
    height: 2rem;
  }

  &--tiny {
    height: 1.5rem;
    font-size: 0.8rem;
    min-width: 1.5rem;
    padding: 0;
  }

  &--invisible {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: none;
    cursor: pointer;
    border: transparent;
    margin-left: 0.2rem;
    height: auto;

    &:hover {
      background-color: transparent;
      box-shadow: 0px 0px white;
    }

    &:focus {
      color: $btn-color-hover;
      outline: 0rem;
      border-color: white;
      box-shadow: 0px 0px white;
    }
  }

  &--full {
    width: 100%;
  }
}

.icon-button {
  display: inline-block;
  border-radius: $base-xlarge-border-radius;
  transition: background-color $base-duration $base-timing;
  height: 2.5rem;
  min-width: 2.5rem;
  font-size: 0.9rem;
  box-shadow: $btn-shadow-light;
  pointer-events: auto;
  cursor: pointer;
  border: none;

  .icon {
    vertical-align: middle;
    height: 20px;
    width: 20px;
    padding: 0;
  }

  .loader-spinner-inline {
    display: none;
    vertical-align: middle;
  }

  &--primary {
    background-color: $primary-color;
    color: white;

    .icon {
      color: white;
    }

    .loader-spinner-inline {
      @include spinner-color(white);
    }

    &:hover {
      background-color: $btn-color-hover;
    }
  }

  &--secondary {
    background-color: transparent;
    border: 1px solid $primary-color;
    box-shadow: none;
    color: $primary-color;

    .icon {
      color: $primary-color;
    }

    .loader-spinner-inline {
      @include spinner-color($primary-color);
    }

    &:hover {
      background-color: darken($base-background-color, 5);
    }
  }

  &.loading {
    .icon {
      display: none;
    }

    .loader-spinner-inline {
      display: inline-block;
    }
  }
}

.inline-button-group {
  &__container {
    display: inline-flex;

    .inline-button:first-of-type {
      border-radius: $base-xlarge-border-radius 0 0 $base-xlarge-border-radius;
      border-right-width: 0;
    }

    .inline-button:last-of-type {
      border-radius: 0 $base-xlarge-border-radius $base-xlarge-border-radius 0;
    }

    .inline-button {
      border-radius: 0;

      &.action-button--primary:disabled {
        border: $base-border;
      }

      &:not(:last-child) {
        border-right-width: 0;
      }

      &:only-child {
        border-radius: $base-xlarge-border-radius;
        border-right-width: 1px;
      }
    }

    &--many {
      .inline-button:last-child {
        border-left-width: 0;
      }
    }
  }
}

button.btn:not(:first-of-type) {
  margin-left: 20px;
}

.btn--small {
  height: auto;
  min-width: 1.5rem;
  padding: 0.25rem 0.25rem;
  margin-left: 0.5rem;
}

// Style a button element to look like a text link
.text-button {
  appearance: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  color: $primary-color;
  @include text-link;
  padding: 0;
  white-space: nowrap;

  &:focus {
    outline-offset: -2px;
  }
}

.split-button {
  display: flex;

  >button {
    background-color: white;
    border: 1px solid $primary-color;
    border-radius: 4px 0 0 4px;
    border-right: 0;
    color: $primary-color;
    cursor: pointer;
  }

  >options-menu .action-button {
    margin: 0 !important;
    border-radius: 0 4px 4px 0;
    border: 1px solid $primary-color;
    height: 2rem;

    &:focus {
      outline: none;
    }

    >.icon {
      height: 0.9rem;
      width: 0.9rem;
      min-height: 12px;
      min-width: 12px;
    }
  }
}