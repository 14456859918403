.search-input {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 450px;
  border-radius: $base-xlarge-border-radius;
  background-color: $base-background-color;
  border: $base-border;
  font-weight: $weight-regular;
  margin-bottom: 0;

  &:focus-within {
    border: $focus-outline;
  }

  >input {
    font-size: 0.875rem;
    border: none;
    border-radius: $base-xlarge-border-radius;
    &:focus {
      outline: none !important;
    }
  }

  >icon {
    margin: 0.5rem;
  }

  .clear-button {
    text-decoration: underline;
    color: $medium-light-grey;
    font-size: 0.75rem;
    padding: 0 0.5rem;
    cursor: pointer;
  }
}