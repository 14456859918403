.inline-edit {
  &__controls {
    display: flex;
    justify-content: flex-end;
    margin-top: $base-extra-small-spacing;
  }

  &__control {
    align-items: center;
    appearance: none;
    background: white;
    border-radius: $base-border-radius;
    border: $base-border;
    box-shadow: $base-box-shadow-light;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: $base-tiny-spacing;
    padding: $base-tiny-spacing;
    transition: background-color $base-duration $base-timing;

    &--confirm {
      .icon {
        color: $color-success;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:active {
      background-color: $lightest-gray;
    }

    .icon {
      height: 1rem;
      width: 1rem;
    }
  }

  &--hidden {
    .inline-edit__controls {
      display: none;
    }
  }
}
