.accordion {
  border: $base-border;

  &__header {
    align-items: center;
    display: flex;
    font-size: 1.2rem;
    justify-content: space-between;
    padding: 1rem;
    position: relative;

    &-group {
      align-items: center;
      display: flex;

      > * {
        margin-right: .5rem; 
        &:last-child {
          margin: 0;
        }
      }      
    }

    &-group .accordion__heading {
      margin-right: $base-extra-small-spacing;
    }
  }

  &__heading {
    color: $dark-blue;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  &__trigger {
    -webkit-appearance: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    .icon {
      transition: $base-transition;
    }
  }

  &__content {

    &[aria-expanded="true"] {
      border-top: $base-border;
      padding: 1rem;
    }

    &[aria-expanded="false"] {
      height: 0px;
      overflow: hidden;
    }
  }

  &--collapsed {
    .accordion__heading {
      margin-bottom: 0;
    }
  }

  &--open {
    .accordion__trigger {
      .icon {
        transform: rotate(-90deg);
      }
    }
  }

  &--fieldset {
    margin-bottom: 1rem;

    fieldset {
      border: none;
      padding: 0;
      margin: 0;    
    }

    .accordion__header-group {
      .icon {
        color: $dark-blue;
      }
    }
  }
}

