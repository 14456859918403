$difference-color: $base-font-color;
$allocation-color: $lightest-gray;
$budget-color: $teal;
$expenditure-color: $gold;
$grid-color: $light-gray;
$mouse-line-color: darken($light-gray, 5);

#chart {
  position: relative;
  display: inline-block;
  font-feature-settings: $font-features-numbers;
}

.expenditure-line {
  fill: none;
  stroke: $expenditure-color;
  stroke-width: 2;
}

.budget-line {
  fill: none;
  stroke: $budget-color;
  stroke-width: 2;
}

.expenditure-dots {
  fill: $expenditure-color;
}

.budget-dots {
  fill: $budget-color;
}

.allocation-bar {
  fill: $allocation-color;
  stroke: darken($allocation-color, 5);
  stroke-width: 2;
}

.mouse-hover {
  &__circle {
    fill: $difference-color;
  }

  &__text {
    fill: $base-font-color;
    text-shadow: 0 0 5px white;
    background: white;
    font-weight: 600;
  }

  &__rect {
    width: 200px;
    height: 20px;
    border-radius: 10px;
  }
}

.mouse-line {
  stroke: $mouse-line-color;
  stroke-width: 1px;
  opacity: 0;

  &-y {
    stroke: $mouse-line-color;
    stroke-width: 1px;
    opacity: 1;
  }
}

.mouse-hover {
  opacity: 0;
}

.mouse-dot {
  opacity: 0;
}

.difference {
  &__line {
    stroke-width: 2px;
    stroke: $difference-color;
    opacity: 0;
  }

  &__text {
    fill: red;
    text-shadow: 0 0 5px white;
    font-weight: 600;

    &.negative {
      fill: $expenditure-color;
    }
  }
}

.quarter-text,
.dollar-text,
.current-year-text {
  font-size: 14px;
  font-weight: 600;
}

.x-label,
.y-label {
  font-family: $base-font-family;
  font-weight: 600;
  font-size: 14px;
}

.x-label {
  font-family: $base-font-family;
}

.x-grid {
  stroke: $grid-color;
  stroke-dasharray: 2, 2;
}

.fifo-chart {

  &__placeholder-badge {
    display: flex;
    align-items: center;

    .loader-spinner-inline {
      @include spinner-color($primary-color);
      margin-left: $base-small-spacing;
    }
  }

  &__legend-list {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: $base-small-spacing 0;

    li {
      margin-right: 2.5rem;
      display: flex;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      &::before {
        content: '';
        height: 0.5rem;
        width: 2rem;
        margin-right: $base-small-spacing;
      }

      &.fifo-chart__legend-allocations {
        &::before {
          background: $allocation-color;
          border: 2px solid darken($allocation-color, 5);
        }
      }

      &.fifo-chart__legend-forecasts {
        &::before {
          background: $budget-color;
        }
      }

      &.fifo-chart__legend-expenditures {
        &::before {
          background: $expenditure-color;
        }
      }
    }
  }

  &__container {
    position: relative;
    overflow: hidden;
    margin-top: $base-spacing;
    border: $base-border;
  }

  &__placeholder {
    z-index: 1;
    background: $lightest-gray;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    align-items: center;
    justify-content: center;
  }
}

.heading-container {
  margin-bottom: 2rem;
}

.current-year-lines {
  stroke-width: 1;
  stroke: $base-font-color;
}

.scenario-header {
  margin-bottom: 1rem;
}

.extension-line {
  stroke-width: 2;
  stroke-dasharray: 2, 2;

  &--budget {
    stroke: $budget-color;
  }

  &--expenditure {
    stroke: $expenditure-color;
  }
}
