.status-item {
  &__container {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;

    .icon {
      margin-right: 0.3rem;
      width: 13px;
      min-width: 13px;
    }
  }

  &__text {
    margin: 0;
  }
}
