.options-menu {
  position: relative;
  margin-left: auto;
  width: intrinsic;

  &.append-to-parent {
    .options-menu__menu {
      top: 30px;
    }
  }

  &__menu {
    background: $base-background-color;
    border-radius: $base-border-radius;
    border-top-right-radius: 0;
    border: $base-border;
    box-shadow: $base-box-shadow-light;
    font-weight: normal;
    list-style: none;
    margin: $base-tiny-spacing 0 0 0;
    min-width: 9rem;
    padding: 0;
    position: absolute;
    right: 0;
    top: -10000px;  // Render outside of screen on init so that it doesn't jump around when final position is calculated
    transition: background-color $base-duration $base-timing;
    max-width: 12rem;
    z-index: 10;
    width: max-content;


    &:before {
      border-color: rgba(0, 0, 0, 0.1) transparent;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      bottom: 100%;
      content: "";
      height: 0;
      right: .8rem;
      position: absolute;
      width: 0;
    }

    &:after {
      border-color: #fff transparent;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      bottom: 100%;
      content: "";
      height: 0;
      right: .95rem;
      position: absolute;
      width: 0;
    }
  }

  &__trigger {
    -webkit-appearance: none;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 $base-extra-small-spacing;

    &:hover {
      cursor: pointer;
    }

    .icon {
      height: 1.5rem;
      margin: 0;
      padding-bottom: 1.5rem;
      width: 1.5rem;
    }

    &--alt {
      background: transparent;
      border: 0;
      border-radius: 2rem;
      height: 1.4rem;
      margin-right: -#{$base-small-spacing};
      margin-left: $base-extra-small-spacing !important;
      min-width: 2rem;
      padding: 0 $base-tiny-spacing;

      &:hover,
      &:focus {
        background: transparent;
        box-shadow: none;
      }
    }
  }

  &__section {
    border-top: 1px solid $light-gray;
  }

  &__menu__item {

    &__link {
      align-items: center;
      appearance: none;
      background: transparent;
      border: 0;
      cursor: pointer;
      display: flex;
      font-size: 0.9rem;
      padding: $base-extra-small-spacing $base-small-spacing;
      text-align: left;
      transition: $base-transition;
      width: 100%;
      color: $btn-color;

      &:hover {
        background-color: $btn-color;
        color: white;
      }

      .icon {
        height: 1rem;
        width: 1rem;
        min-width: 1rem;
        color: inherit;
        margin-right: $base-extra-small-spacing;
      }
    }

    &--danger {
      .options-menu__menu__item__link {
        color: $color-error;

        &:hover {
          background-color: $color-error;
          color: white;
        }
      }
    }
  }

  [slot="label"] {
    padding-right: 0.2rem;
  }
}
