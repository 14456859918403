.fieldset {
  background-color: transparent;
  border: none;
  margin: 0 0 $base-small-spacing;
  min-width: auto;
  padding: 0;

  > *:last-child {
    margin-bottom: 0;
  }

  .legend {
    color: $primary-color;
    font-size: $base-font-size;
    font-weight: $weight-semibold;
    margin-bottom: $base-extra-small-spacing;
  }

  &.flex {
    radio-check {
      margin-right: $base-small-spacing;
    }
  }

  radio-check {
    margin-bottom: $base-extra-small-spacing;
  }
}

input,
label,
select {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

label, .label-text {
  display: inline-block;
  color: $primary-color;
  border: none;
  font-weight: $weight-semibold;
  margin: 0 0.25rem 0.375rem 0;

  &.required::after {
    content: "*";
    color: $color-error;
    padding-left: 0.25rem;
    font-size: $base-font-size;
  }

  abbr {
    display: none;
  }

  a {
    text-decoration: underline;
  }
}

li.label-text {
  display: list-item;
}

#{$form-inputs} {
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-sizing: border-box;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  padding: calc($base-extra-small-spacing / 2);
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:disabled {
    background-color: #e9ebf5;
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }
}

.input {
  &--highlighted {
    @include highlight;

    &--positive {
      @include highlight--positive;
      position: relative;

      icon[iconid="arrow"] {
        @include highlight__arrow;
        color: darken($color-success, 10%);
        transform: rotate(-90deg);
      }

      input-field {
        margin-left: 1.75rem;
      }
    }

    &--neutral {
      @include highlight--neutral;
      position: relative;

      icon[iconid="arrow"] {
        @include highlight__arrow;
        color: darken($gold, 10%);
        transform: rotate(90deg);
      }

      input-field {
        margin-left: 1.75rem;
      }
    }
  }
}

textarea {
  resize: vertical;
  min-height: 7.5rem;
}

input[type="search"] {
  appearance: none;
}

input[type="number"] {
  font-feature-settings: $font-features-numbers;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: .375rem;

  + label {
    display: inline-block;
  }
}

input[type="file"] {
  margin-bottom: $base-small-spacing;
  width: 100%;
}

select {
  margin-bottom: $base-spacing;
  max-width: 100%;
  width: auto;
}

.optional-label {
  color: $medium-dark-gray;
  font-size: $base-font-size;
}

.search-form {
  display: inline-block;
  width: 100%;
}

.contact-invite {
  font-size: 1rem;
  text-decoration: underline;
  margin-bottom: $base-small-spacing;

  a, a:visited {
    color: $medium-gray;
  }

  a:hover {
    color: lighten($action-color, 30%);
  }
}

input-field {
  margin-bottom: $base-spacing;
  display: block;

  input[type="text"], textarea {
    margin-bottom: 0;
  }

  &__revert-button {
    @include clear-button;
    position: static;
    right: auto;
    top: auto;
  }

  &__revert-button-container {
    display: flex;
    height: 100%;
    position: absolute;
    align-items: center;
    right: 5px;
    top: 0;
    z-index: 1;
  }

  // Prevent non-wrapping "white-space" styles from overflowing page
  .read-only__container * {
    white-space: normal !important;
  }
}

.po-table {
  input-field.align-right, .align-right input-field, .align-data--right input-field {
    justify-content: end;
  }

  .align-right input {
    text-align: right;
  }
}

.po-table td,th {
  input-field, po-select {
    display: flex;
    align-items: center;

    .action-button {
      border-radius: $base-border-radius;
      padding: 0.5rem;
      min-width: 0;
      height: auto;
      line-height: normal;
    }

    .revert-button {
      display: flex;
      align-items: center;
    }

    .revert-button-container {
      margin-left: 0.5rem;
    }

    > div:not(:has(.read-only__container)) {
      flex: 1 auto;
    }
  }
}

type-ahead {
  margin-bottom: $base-spacing;
  display: block;
}

.field-group {
  margin: 0 0 $base-spacing;

  &--child {
    margin-left: $base-spacing;
  }

  &--short {
    max-width: 40rem;
  }

  &--xsmall-input-field {
    input, textarea {
      width: 3.5rem;
      max-width: 3rem;
    }
  }

  &--small-input-field {

    input, textarea {
      max-width: 7rem;
    }
  }

  &--medium-input-field {

    input, textarea {
      max-width: 14rem;
    }
  }

  &--large-input-field {

    input, textarea {
      max-width: 30rem;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &__heading {
    display: inline-block;
    font-size: .75rem;
    color: $primary-color;
    border: none;
    font-weight: $weight-semibold;
    margin: 0 0.25rem 0.375rem 0;
  }
}

input-group {
  margin-bottom: $base-spacing;
  display: block;

  legend {
    &.required::after {
      content: "*";
      color: $color-error;
      padding-left: 0.25rem;
      font-size: $base-font-size;
    }
  }

  po-select {
    margin-bottom: 0;
  }

  ol {
    padding-left: $base-font-size;

    li {
      padding-left: $base-extra-small-spacing;
    }
  }
}

.input-footer {
  display: flex;
  justify-content: flex-end;

  .error-text {
    flex-grow: 1;
    padding-bottom: 0;
  }

  .error-text-none {
    flex-grow: 1;
    padding-bottom: 0;
    display: none;
  }

  .char-counter {
    flex-basis: 4rem;
    float: none;
    text-align: right;
  }
}

.help-text {
  margin-bottom: $base-small-spacing;

  p, ul, ol {
    color: $medium-dark-gray;
    margin-bottom: $base-small-spacing;
  }
}

.form-help__text {
  margin-bottom: $base-small-spacing;
}

.char-counter {
  margin-top: $base-extra-small-spacing;
  color: $medium-dark-gray;
  float: right;
  font-feature-settings: $font-features-numbers;

  &__input--limit {
    color: $color-required;
    font-weight: normal;
  }

  &::after {
    clear: both;
  }
}

.input-with-button {
  display: flex;
  justify-content: space-between;

  &__input {
    flex-grow: 1;
  }

  &__button {
    padding-top: 1.625rem;
    margin-left: 1.75rem;
  }
}

$border-error: 5px solid $color-error;
form:not(.first-touch):not(.po-select__search-form) {
  input:not([type=checkbox]):not([type=radio]), textarea, .fr-view {
    &.ng-invalid {
      border-left: $border-error;
    }
  }
}

.first-touch {
  input:not([type=checkbox]):not([type=radio]), textarea, .fr-view {
    &.ng-invalid.ng-touched {
      border-left: $border-error;
    }
  }

  input[type="checkbox"].ng-invalid.ng-touched {
    + label {
      color: $color-error;
    }
  }
}

.ng-invalid {
  .error-text--radio-tiles {
    color: $color-error;
    font-weight: $weight-bold;
    &::before {
      content: "*";
      margin-right: .25rem;
    }
  }
}

.error-highlight {
  outline: 2px dashed $color-error;
  outline-offset: $focus-outline-offset;
}

multi-select {
  display: block;
  margin-bottom: $base-small-spacing;
}

/*********** SWP Workplan Tab Styles  - MJM Sept 2017 ******/
.multi-checkbox-wrapper label {
  display: block;
}

.multi-checkbox-wrapper.ng-invalid label {
  color: $color-error;
  font-weight: $weight-bold;
}

.multi-checkbox-wrapper.ng-invalid label:before {
  content: "*";
  color: $color-error;
  font-weight: $weight-bold;
  margin-right: .25rem;
}

.multi-checkbox-wrapper .checkbox-option {
  display: inline-block;
}

.multi-checkbox-wrapper .checkbox-option:not(:last-child) {
  margin-right: 1.5rem;
}
/**** end SWP Workplan Tab Styles ****/

.error-label {
  color: $color-error;
}

.error-asterisk {
  color: $color-error;
  font-weight: $weight-semibold;
}

.label {
  color: $dark-gray;
  font-size: $base-font-size;
  font-weight: normal;
  margin: 0;
}

.removable-input {
  display: flex;

  .icon {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}

.bidirectional-control-input {
  display: flex;
  align-items: center;

  &__align-items-start {
    align-items: flex-start;
  }
}

.error-text {
  display: block;
  color: $color-error;
  font-weight: $weight-semibold;

  &--inline {
    display: inline;
  }

  &__list {
    margin: $base-extra-small-spacing 0 0;
    list-style-type: none;
    padding: 0;
  }
}

.month-day {
  &__select-container {
    display: flex;
    flex-wrap: wrap;
  }

  po-select {
    width: 5rem;
    margin-bottom: 0;

    &:first-child {
      margin-right: $base-extra-small-spacing;
    }
  }
}

.compose-email {
  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__attachment-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__attachment-item {
    margin-bottom: $base-small-spacing;
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  &__attachment-button-text {
    display: flex;
    margin-bottom: $base-small-spacing;

    .action-button {
      margin-right: $base-spacing;
    }
  }

  &__send-buttons {
    display: flex;
  }
}

/////////////////////////////////
// Froala Editor Overrides
/////////////////////////////////

.po-theme.fr-box {
  a {
    @include text-link;
  }

  .fr-toolbar {
    box-shadow: none;
    border: $base-border;
    border-bottom: none;
  }

  .fr-counter {
    font-family: $base-font-family;
    color: $base-font-color;
  }

  .fr-view {
    &:focus {
      outline: $focus-outline;
      outline-offset: -8px;
    }
  }

  &.fr-basic .fr-element {
    padding: $base-extra-small-spacing;
    min-height: 7rem;
  }

  &.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
    border: $base-border;
  }
}

.po-theme.fr-tooltip {
  font-family: $base-font-family;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: $weight-semibold;
}

/////////////////////////////////
// Material Design Overrides
/////////////////////////////////
.date-picker, .date-time-picker {
  .mat-form-field-suffix {
    display: flex;
    align-items: center;
    justify-content: center;

    .mat-datepicker-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      &:focus {
        outline: $focus-outline;
      }

      .mat-button-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    .mat-icon {
      color: $medium-dark-gray;
    }
  }
}

.mat-slide-toggle {

  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: $primary-color;
    }

    .mat-slide-toggle-thumb {
      background-color: $base-background-color;
    }
  }

  .mat-slide-toggle-bar {
    height: 20px;
    border-radius: 10px;
    background-color: $light-gray;

    .mat-slide-toggle-thumb-container {
      top: 3px;
      left: 3px;

      .mat-slide-toggle-thumb {
        height: 14px;
        width: 14px;
      }
    }
  }

  .mat-slide-toggle-content {
    font-family: $base-font-family;
    font-size: 0.875rem;
    font-weight: $weight-regular;
    color: $medium-dark-gray;
  }

  .mat-slide-toggle-ripple {
    display: none;
  }

}