.tool-tray {
  position: relative;
  background-color: white;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 0 $base-spacing;
  min-height: 4.5rem;
  box-shadow: $base-box-shadow-light-top;

  .btn {
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.tool-tray-status {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;

  &.fadeInRight {
    opacity: 0;
    animation: fadeInRight 0.35s $base-timing $base-delay forwards;
  }

  &.fadeOut {
    opacity: 1;
    animation: fadeOut 0.35s $base-timing $base-delay forwards;
  }

  &.bounce {
    animation: bounce 0.5s $base-timing ($base-delay * 0.5);
  }

  &.shake {
    animation: shake 0.5s $base-timing ($base-delay * 0.5);
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: .5rem;
  }

  .message {
    margin: 0;
  }
}

.message {
  display: inline-block;

  .loader-spinner-inline {
    @include spinner-color($primary-color);
    margin-left: $base-extra-small-spacing;
    top: .25rem;
  }
}

.contact-icon {
  margin-right: 1rem;
}

.tool-tray-status.fadeOut {
  opacity: 1;
  animation: fadeOut 0.35s 3s ease-in-out forwards;
}

.tool-tray-status.fadeInRight.fadeOut {
  opacity: 0;
  animation: fadeInRight 0.35s ease-in-out forwards, fadeOut 0.35s 3s ease-in-out forwards;
}
