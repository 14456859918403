.page-wrapper {
  display: flex;
  background-color: $page-background-color;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  &--pushed {
    padding-top: $environment-indicator-height;

    .skip-link {
      transform: translateY(-5rem);

      &:focus {
        transform: translateY(-1.75rem);
      }
    }
  }

  &--pushed-double {
    padding-top: $environment-indicator-height * 2;

    .skip-link {
      transform: translateY(-10rem);

      &:focus {
        transform: translateY(-1.75rem);
      }
    }
  }

  ul.options-menu {
    &__menu {
      z-index: 50000;
    }
  }
}

.content-body {
  position: relative;
  display: flex;
  flex-grow: 1;

  &__tabs {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-flex;
    z-index: 35;

    .tab-section {
      background: white;
      padding: $base-tiny-spacing $base-small-spacing;
      box-shadow: $base-box-shadow-light;
      border-radius: 0 0 0 $base-large-border-radius;
      align-items: center;

      &:not(:first-child) {
        border-left: $base-border;
      }
    }

    transition: right 200ms ease;

    &.comments {
      right: $comments-container-width;
    }
  }

  &__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $base-spacing;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition: right 200ms ease;

    &.visible {
      left: $nav-workflow-width;
    }

    &.comments {
      right: $comments-container-width;
    }

    &--has-sticky-card {
      // padding-top: 0;
    }
  }

  &__scroll-anchor {
    position: absolute;
    top: 0;
  }

  ul.options-menu {
    &__menu {
      z-index: 10;
    }
  }
}

.container {
  &--narrow {
    max-width: 60rem;
  }
}

.secondary-content {
  flex-basis: $secondary-content-width;
  transition: flex-basis 150ms ease;
  position: relative;

  *:focus {
    outline: 1px solid $focus-outline-color-alt;
    outline-offset: 0;
  }
}

.content-footer {
  margin-top: auto;
}

.sticky-card {
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  display: block;
  margin: -#{$base-spacing} -#{$base-spacing} $base-spacing;
  position: -webkit-sticky;
  position: sticky;
  padding: $base-spacing;
  top: -#{$base-spacing};
  z-index: 20;

  &--no-overlay {
    z-index: 0;
  }
}

.page--no-sidebar,
.page--login,
.page--password-reset {
  .secondary-content {
    display: none;
  }

  .main-content {
    left: 0;
  }
}

@media screen {
  .main-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
