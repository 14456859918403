.labeled-text {
  margin: 0 0 1rem;

  &:last-child {
    margin: 0;
  }

  &.success {
    color: $green;
  }

  &.error {
    color: $red;
  }

  label  {
    margin-bottom: 0;
  }
}
