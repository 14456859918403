@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,600i,700,700i");

html {
  font-size: 100%;
}

body {
  background-color: $lightest-gray;
  color: $base-font-color;
  font-family: $base-font-family;
  font-feature-settings: "kern", "liga", "pnum";
  font-size: $base-font-size;
  line-height: $base-line-height;
}

p {
  margin: 0 0 1rem 0;
}

strong {
  font-weight: $weight-bold;
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

.text-info {
  color: $medium-light-grey;
}

.text-success {
  color: $color-success;
}

.text-error {
  color: $color-error;
}

.text-removed {
  text-decoration: line-through;
}

.text--small {
  font-size: 0.9rem;
}

.text-diff {
  &--added {
    background-color: tint($lime, 80%);
    color: $green;
    font-weight: $weight-bold;
  }

  &--removed {
    background-color: tint($red, 90%);
    color: $red;
    font-weight: $weight-regular;
  }
}

.error-text[level="success"] {
  color: #080;
}

.number-danger {
  color: orangered;
}

.status--incomplete {
  font-weight: $weight-semibold;
  text-align: center;
}
