.system-notification {
  align-content: center;
  background-color: $teal;
  color: white;
  display: flex;
  font-weight: $weight-semibold;
  justify-content: center;
  line-height: 1.6rem;
  max-height: $environment-indicator-height;
  overflow: hidden;
  padding: $base-extra-small-spacing;
  position: fixed;
  text-align: center;
  transition: max-height .35s ease-in-out;
  width: 100%;
  z-index: 99;

  p {
    margin-bottom: 0;
    margin-right: 0.5rem;
    max-width: 70rem;
  }

  &__toggle {
    max-height: 1.4rem;
    min-width: 1.25rem;
    position: relative;

    &:before {
      content: '...';
      display: block;
      left: -1.5rem;
      position: absolute;
    }
  }

  &--expanded {
    max-height: 6rem; // Enables height transition to be smooth

    .system-notification__toggle:before {
      display: none;
    }
  }

  &--info {
    background-color: $lightest-blue;
    color: $primary-color;
    font-weight: $weight-regular;
  }

  &--pushed {
    top: $environment-indicator-height;
  }

  .icon {
    color: $primary-color;
    margin-right: 0.5rem;
    min-width: 4.5rem;
    top: 0.15rem;
  }

  span {
    font-weight: $weight-semibold;
  }
}
