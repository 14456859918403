.app-dashboard {
  display: grid;
  min-height: 100%;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1.5rem;

  app-action-items {
    min-width: 450px;
  }

  app-bookmarks {
    grid-column: 1 / 3;

    project-filter {
      display: none;  // TODO: Hiding the filter for now
    }
  }

  *:focus {
    outline: none;
  }

  // TODO: Update all buttons to use these styles?
  app-calendar, app-action-items {
    .action-button {
      color: $medium-dark-gray;
      background-color: transparent;
      font-size: 0.9rem;
      padding: 0 5px;

      &--small {
        height: 1.6rem;
      }

      &.sync-calendar { // TODO: Fix properly
        color: white;
        background-color: $primary-color;
      }
    }

    .action-button--secondary {
      border: none;

      .icon {
        color: $medium-dark-gray;
      }
    }
  }

  .card { // TODO: Update card css to use these styles?
    margin: 0;
  }

  .kabob-button {
    display: block;
    border-radius: 50%;
    padding: 0;
  }
}