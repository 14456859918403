.full-page-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 800;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__header {
    padding: $base-small-spacing;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    background: $page-background-color;
    box-shadow: $base-box-shadow-light;
    z-index: 2;
  }

  &__heading {
    color: $primary-color;
    margin: 0;
    font-size: 1.4rem;

    icon {
      vertical-align: middle;
      height: 1.5rem;
      width: 1.5rem;
      margin-bottom: .25rem;
    }
  }

  &__body {
    padding: $base-small-spacing;
    background: white;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__footer {
    padding: $base-small-spacing;
    background: $page-background-color;
    box-shadow: $base-box-shadow-light-top;
    z-index: 2;

    &__right {
      text-align: right;
    }

    .action-button {
      padding: 0 2rem;
      
      + .action-button {
        margin-left: $base-small-spacing;
      }
    }
  }

  &.minimal {
    .full-page-modal {

      &__container {
        max-width: 65rem;
        max-height: 50rem;
        min-height: 35rem;
        height: auto;
      }

      &__header {
        background: white;
        box-shadow: none;

        .action-button {
          border: none;
        }
        .action-button:focus {
          outline: none;
        }

        icon {
          color: $medium-gray;
          height: 1.75rem;
          width: 1.75rem;
        }
      }

      &__heading {
        color: $dark-gray;
      }

      &__footer {
        background: white;
        box-shadow: none;
      }
    }
  }

  .segmented-control {
    z-index: 1;
    flex-shrink: 0;
  }
}

.page-wrapper {
  .full-page-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
  }
}

@media screen and (min-width: 600px) {
  .full-page-modal {
    &--small {
      .full-page-modal__container {
        max-width: 50rem;
        max-height: 50rem;
        min-height: 35rem;
        height: auto;
      }
    }

    &--medium {
      .full-page-modal__container {
        max-width: 60rem;
        max-height: 70rem;
        min-height: 50rem;
        height: auto;
      }
    }

    &__container {
      border-radius: 0.5rem;
      overflow: hidden;
      width: 90%;
      height: 95%;
      max-width: 70rem;
      box-shadow: $base-box-shadow;
    }

    &__header {
      padding: $base-spacing;
    }

    &__heading {
      font-size: 1.8rem;
    }

    &__body {
      padding: $base-spacing;
    }

    &__footer {
      padding: $base-spacing;

      .action-button {
        + .action-button {
          margin-left: $base-spacing;
        }
      }
    }
  }
}
