.panel {
  background-color: $base-background-color;
  border: $base-border;
  margin-bottom: 1rem;
 
  &__section-top {
    position: relative;
    align-items: center;
    padding: 1rem; 
  }

  &__section-collapse {
    border-top: $base-border;
    padding: 0;

    &--collapsed {
      height: 0;
    }

    &--expanded {
      height: auto;
    }
  }
  
  &__section-main {
    padding: 1rem;

    p {
      margin-bottom: 1rem;
    }
  }
}
