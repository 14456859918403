h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $primary-color;
  font-weight: normal;
  line-height: $base-heading-line-height;
  margin: 0 0 $base-small-spacing;

  &.required::after {
    content: "*";
    color: $color-error;
    padding-left: 0.25rem;
    font-size: $base-font-size;
  }

  &.base-font-color {
    color: $base-font-color;
  }
}

h1,
.h1 {
  font-size: 2.5rem;
  font-weight: 400;
}

h2,
.h2 {
  font-size: 2.1rem;
  font-weight: 400;
}

h3,
.h3 {
  font-size: 1.6rem;
}

h4,
.h4 {
  font-size: 1.2rem;
  font-weight: 400;
}

h5,
.h5 {
  font-size: 1rem;
  font-weight: 400;
}

h6,
.h6 {
  font-size: $base-font-size;
  font-weight: $weight-semibold;
  color: $base-font-color;
}

.title--login,
.title--request-access {
  font-size: 1.875rem;
  font-weight: normal;
  margin-bottom: 1rem;
  text-align: center;
}

.title--4 {
  color: $primary-color;
  font-weight: $weight-semibold;
}
