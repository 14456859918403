.history-list {
  list-style: none;
  margin: 0 0 0 $base-extra-small-spacing;
  padding: 0;

  &__item {
    padding-left: $base-spacing;
    position: relative;
    margin-bottom: $base-spacing;

    &-name,
    &-status,
    &-date {
      font-weight: normal;
    }

    &-title {
      font-size: 1.2rem;
      color: $dark-blue;
    }

    &-name {
      margin-right: $base-extra-small-spacing;

      a {
        text-decoration: underline;
      }
    }

    &-status {
      border-radius: $base-large-border-radius;
      font-size: 0.7rem;
      font-weight: $weight-bold;
      letter-spacing: 1px;
      padding: 0 $base-extra-small-spacing;
      text-transform: uppercase;
      background-color: lighten($color-info, 32%);
      color: darken($color-info, 35%);
      white-space: nowrap;
    }

    &-date {
      font-size: 0.9rem;
      margin-bottom: $base-small-spacing;
    }

    dl,
    dd {
      margin: 0;
    }

    &:before {
      height: 1.1rem;
      width: 1.1rem;
      background: $dark-blue;
      border-radius: 50%;
      border: 0.25rem solid white;
      content: "";
      display: block;
      left: -0.7rem;
      position: absolute;
      top: 0.4rem;
      z-index: 1;
    }

    &:after {
      background: $light-gray;
      bottom: -2rem;
      content: "";
      display: block;
      left: -0.25rem;
      position: absolute;
      top: 0.45rem;
      width: 0.2rem;
    }

    &:last-child {
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }
  }

  &__links {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__link {
    align-items: center;
    display: flex;
    margin-right: $base-small-spacing;
    text-decoration: underline;

    &-item {
      display: inline-block;
      font-size: 0.9rem;

      .icon {
        margin-right: $base-tiny-spacing;
      }
    }
  }
}
