// Typography
$base-font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial",
  sans-serif;
$font-features-numbers: "kern", "liga", "tnum";

// Font Sizes
$base-font-size: 1rem;

// Line Height
$base-line-height: 1.5;
$base-heading-line-height: 1.2;
$weight-bold: 600;
$weight-semibold: 600;
$weight-regular: 200;

// Border Radius
$base-border-radius: 2px;
$base-large-border-radius: 5px;
$base-xlarge-border-radius: 24px;

// Spacing
$base-large-spacing: 2rem;
$base-spacing: 1.5rem;
$base-small-spacing: 0.75rem;
$base-extra-small-spacing: 0.5rem;
$base-tiny-spacing: 0.25rem;

// Colors
//Brand Colors
$gold: #fbab18;
$teal: #44bbd7;
$royal: #1d3767;
$royal-lighter: #6895e7;
$medium-blue: #014f7d;
$blue: #61a4d9;
$bright-blue: #3dc9ff;
$dark-blue: $royal;
$darkest-blue: #021c3b;
$lightest-blue: #e5f0f9;
$light-blue: #9dc8ea;
$red: #cf110d;
$green: #15812a;
$green-lighter: #60dd78;
$lime: #91c956;
$yellow: #e8a000;
$light-yellow: #f9f6ed;
$orange: #f5bd4a;
$salmon: #f26c55;

$dark-gray: #333;
$medium-light-grey: #757575;
$medium-dark-gray: #666;
$medium-gray: #999;
$light-gray: #ccc;
$lightish-gray: #eeeeee;
$lighter-gray: #eaeaea;
$lightest-gray: #f3f3f3;
$extra-light-gray: #f7f7f7;

$primary-color: $royal;
$primary-color-lighter: $royal-lighter;
$secondary-color: $gold;

$color-success: $green;
$color-error: $red;
$color-info: $blue;
$color-warning: $orange;
$color-required: #a94442;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $primary-color;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Effects
$base-box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
$base-box-shadow-light: 0 2px 5px rgba(0, 0, 0, 0.15);
$base-box-shadow-light-top: 0 -2px 5px rgba(0, 0, 0, 0.1);
$base-box-shadow-big: 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%), 0px 11px 15px -7px rgb(0 0 0 / 20%);

// Background Colors
$page-background-color: $lightest-gray;
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);
$error-background-color: #fac3ba;
$neutral-background-color: #f9f6ed;
$positive-background-color: #bbe58e;

$bg-colors: (
  "gold": $gold,
  "teal": $teal,
  "royal": $royal,
  "royal-lighter": $royal-lighter,
  "blue": $blue,
  "dark-blue": $dark-blue,
  "darkest-blue": $darkest-blue,
  "light-blue": $light-blue,
  "red": $red,
  "green": $green,
  "green-lighter": $green-lighter,
  "lime": $lime,
  "yellow": $yellow,
  "light-yellow": $light-yellow,
  "orange": $orange,
  "salmon": $salmon
);

@each $name,
$color in $bg-colors {
  .bg-#{$name} {
    background-color: $color;
  }
}

// Focusing
$focus-outline-color: $medium-blue;
$focus-outline-color-alt: $gold;
$focus-outline: 2px solid $focus-outline-color;
$focus-outline-alt: 2px solid $focus-outline-color-alt;
$focus-outline-offset: 2px;
$focus-outline-inset: -2px;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(black, 0.1);
$form-box-shadow-focus: $form-box-shadow,
  0 0 3px adjust-color($primary-color, $lightness: -5%, $alpha: -0.3);
$form-inputs: "input[type='color'], input[type='date'], input[type='datetime'], input[type='datetime-local'], input[type='email'], input[type='month'], input[type='number'], input[type='password'], input[type='search'], input[type='tel'], input[type='text'], input[type='time'], input[type='url'], input[type='week'], input:not([type]), textarea, input[type='phone'], select[multiple=multiple]";

// Animations
$base-duration: 200ms;
$base-timing: ease-out;
$base-transition: all $base-duration $base-timing;
$base-delay: 750ms;

// Buttons
$btn-font-size: $base-font-size;
$btn-color: $primary-color;
$btn-shadow-light: $base-box-shadow-light;
$btn-shadow: $base-box-shadow;
$btn-color-hover: lighten($btn-color, 10%);

// Navigation
$nav-workflow-width: 9rem;
$site-nav-path-color: $teal;
$site-nav-border-color: rgba(white, 0.2);
$site-nav-background-color: $darkest-blue;
$site-nav-item-height: 2.5rem;
$site-nav-indicator-size: 7px;
$site-nav-left-spacing: 1.5rem;
$site-nav-right-spacing: 8px;
$site-nav-text-color: white;
$site-nav-text-padding: 3px;
$site-nav-color-active: $bright-blue;
$site-nav-transition-duration: 0.2s;
$site-nav-expand-button-size: 1.6rem;
$site-nav-expand-button-icon-size: 1rem;
$site-nav-collapsed-width: 4.25rem;

// Layout
$secondary-content-width: 15rem;
$environment-indicator-height: 2.5rem;
$comments-container-width: 25rem;