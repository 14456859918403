.profile-card {
  position: relative;

  &__container {
    display: flex;
    justify-content: space-between;
    padding: $base-spacing;
    border: $base-border;
    border-radius: $base-border-radius;
    background: white;
    margin-bottom: $base-spacing;
    position: relative;
  }

  &__user-data {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-between;
    margin-top: $base-small-spacing;
  }

  &__data-section {
    margin-right: $base-small-spacing;
    padding-right: $base-small-spacing;

    &:last-of-type {
      border-left: $base-border;
      margin-right: 0;
      padding-right: 0;
      padding-left: $base-small-spacing;
      width: 20rem;
    }
  }

  &__name {
    font-size: 1.2rem;
    text-decoration: underline;
    margin-bottom: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__data-item {
    margin-bottom: 0.3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__options {
    position: absolute;
    right: 1rem;
    top: 1rem;

    .options-menu {
      position: relative;
      right: -1px;
      top: -1px;

      &__trigger {
        background-color: $lightest-gray;
        border: $base-border;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .action-button {
      height: auto;
      margin-bottom: $base-small-spacing;
      padding: $base-extra-small-spacing $base-small-spacing;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}