.contacts {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  &__contact {
    font-size: $base-font-size;
    border: $base-border;
    margin-bottom: 1rem;
    width: 100%;

    &__heading {
      font-size: $base-font-size;
      font-weight: $weight-bold;
      background: #eee;
      color: $primary-color;
      margin: 0;
      padding: .5rem;
      word-wrap:break-word;
    }

    &--with-options {
      .contacts__contact {
        &__options {
          margin: $base-extra-small-spacing $base-extra-small-spacing 0;
          span {
            color: $base-font-color;
            font-family: $base-font-family;
            font-weight: $weight-bold;
          }
        }

        &__details {
          padding-top: 0;
        }
      }
    }

    p {
      margin: 0;
      padding: .5rem;
      word-wrap: break-word;
    }
  }
}

@media screen and (min-width: 768px) {
  .contacts {
    &__contact {
      margin-right: 3%;
      width: 48%;

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }

    &--wide {
      .contacts__contact {
        margin-right: 0;
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .contacts {
    &__contact {
      margin-right: 2%;
      width: 32%;

      &:nth-of-type(2n) {
        margin-right: 2%;
      }

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }

    &--wide {
      .contacts__contact {
        margin-right: 0;
        width: 70%;
      }
    }
  }
}
