.workflow-steps {
  &__group {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: darken($lightest-gray, 5);
    left: 0;
    top: 0;
    bottom: 0;
    width: $nav-workflow-width;
    overflow-y: auto;
    border-right: $base-border;
  }

  &__heading {
    font-weight: $weight-semibold;
    font-size: $base-font-size;
    margin-bottom: 0;
    padding: $base-extra-small-spacing;
    background-color: $secondary-color;
    color: $site-nav-background-color;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &__subheading {
    font-weight: $weight-semibold;
    font-size: $base-font-size;
    margin-bottom: 0;
    color: $base-font-color;
  }

  &__subsection {
    padding: $base-tiny-spacing $base-extra-small-spacing;
    background-color: $light-gray;
  }

  &__back,
  &__link {
    display: flex;
    align-items: center;
    transition: background-color $base-duration $base-timing;

    &:hover,
    &:focus-within {
      background-color: white;
    }

    &:focus {
      outline-offset: -2px;
    }

    .icon {
      height: 1.2rem;
      width: 1.2rem;
      min-width: 1.2rem;
      color: $medium-gray;
    }
  }

  &__back {
    padding: $base-extra-small-spacing;

    .icon {
      margin-right: $base-tiny-spacing;
    }
  }

  &__menu {
    margin: 0;
    padding: 0;
  }

  &__step {
    display: flex;
    position: relative;
    border-bottom: $base-border;

    &-title {
      font-weight: 600;
      margin: 0;
      line-height: 1.2;
      color: $base-font-color;
    }

    &-status-text {
      font-size: 0.9rem;
      margin: 0;
      color: $color-error;

      &.completed {
        color: $medium-dark-gray;
      }

      &.incomplete {
        color: $color-error;
      }
    }

    &--empty {
      background-color: $light-yellow;
      padding: $base-small-spacing;
      font-size: 0.9rem;
    }

    &--separated {
      padding-top: 0.25rem;

      &::before {
        content: "";
        position: absolute;
        height: 0.25rem;
        left: 0;
        right: 0;
        top: 0;
        border: $base-border;
        border-top: 0;
        background-color: $light-gray;
      }
    }
  }

  &__link {
    height: 100%;
    width: 100%;
    color: $base-font-color;
    padding: $base-small-spacing $base-extra-small-spacing;

    &-content {
      flex-grow: 1;
    }

    &.active {
      background: white;

      .workflow-steps__step-title {
        color: $base-font-color;
      }

      .workflow-steps__link-indicator {
        background-color: $site-nav-color-active;
        border-color: $site-nav-color-active;
      }
    }
  }

  &__link-indicator {
    width: $site-nav-indicator-size;
    height: $site-nav-indicator-size;
    min-width: $site-nav-indicator-size;
    min-height: $site-nav-indicator-size;
    border-radius: 50%;
    border: 1px solid $medium-gray;
    margin-right: $base-extra-small-spacing;
    background: white;
    z-index: 1;

    &--disabled {
      opacity: 0;
    }
  }
}
