po-select {
  display: block;
  margin-bottom: $base-spacing;

  &.po-select--spaced {
    margin-bottom: 12rem;
  }

  &__revert-button {
    @include clear-button;
    position: static;
    right: auto;
    top: auto;
  }

  &__revert-button-container {
    display: flex;
    height: 100%;
    position: absolute;
    align-items: center;
    right: 5px;
    top: 0;
    z-index: 1;
  }
}

.po-select {
  &__group {
    position: relative;

    .po-select__search-input {
      margin-bottom: 0;
      padding-right: 1.8rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .type-ahead__results-container {
      top: 0;
    }

    &__inline {
      display: flex;
      position: relative;
      align-items: center;

      label {
        margin-right: $base-small-spacing;
        margin-bottom: 0;
      }

      input {
        min-width: 250px;
      }
    }
  }

  &__list-wrapper {
    position: relative;
  }

  &__input-container {
    position: relative;
  }

  &__selection-list {
    padding-top: $base-small-spacing;

    &-item {
      position: relative;
      background: $medium-dark-gray;
      color: white;
      cursor: pointer;
      display: inline-flex;
      margin: 0 $base-small-spacing $base-small-spacing 0;
      align-items: center;
      text-transform: none;
      text-align: left;
      letter-spacing: normal;
      height: auto;
      min-height: 2rem;
      padding: $base-extra-small-spacing $base-small-spacing;

      .icon-host {
        line-height: 0;
      }

      .icon {
        color: white;
        margin-left: 0.5rem;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        background: $color-error;
      }
    }
  }

  &__label,
  &__select {
    display: none;
  }

  &__arrow-icon-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  &__arrow-icon {
    height: 12px;
    width: 12px;
    min-height: 12px;
    min-width: 12px;
  }

  &__clear-button {
    @include clear-button;
    position: static;
    right: auto;
    top: auto;
  }

  &__clear-button-container {
    display: flex;
    height: 100%;
    position: absolute;
    align-items: center;
    right: 5px;
    top: 0;
    z-index: 1;
  }
}

:not(.po-select__clear-button-container:has(*)) + .po-select__arrow-icon-container {
  background-color: white;
  margin: 1px;
  border-left: $base-border;
}