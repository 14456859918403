.list-filter {
  &__container {
    margin-bottom: $base-spacing;
    background: $lightest-gray;
    border: 1px solid $base-border-color;
    padding: $base-spacing;

    input, .chosen-container, po-select, input-field, fieldset {
      margin-bottom: 0;
    }
  }

  &__fields-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: $base-spacing;

    &--compact {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
  }

  &__heading-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__buttons {
    margin-top: $base-spacing;
  }

  &__apply-button {
    margin-right: $base-small-spacing;
  }
}
