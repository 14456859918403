.card {
  position: relative;
  background-color: white;
  border: $base-border;
  border-radius: $base-border-radius;
  padding: $base-spacing;
  margin: 0 0 $base-spacing;

  &--prev-efforts p,
  &--futuregoals p,
  &--intandcoord p,
  &--support p {
    margin-bottom: 1rem;
  }

  &--centered {
    text-align: center;
  }

  &--no-padding {
    padding: 0;

    .card-title {
      padding: $base-spacing $base-spacing 0;
    }
  }

  &--elevated {
    box-shadow: $base-box-shadow-light;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--darkened {
    background: $lightest-gray;
  }

  &--darkened-more {
    background: darken($lightest-gray, 5%);
  }

  &--nested {
    border: none;
    padding: 0;

    .project-card__collapsible {
      padding-right: 3rem;

      &__trigger {
        border: none;
        position: inherit;
      }
    }
  }

  .icon--disclosure-down {
    p {
      margin-bottom: 1rem;
    }
  }

  .card-title {
    margin-bottom: $base-spacing;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }

  .card-section {
    margin-bottom: $base-spacing;
    max-width: 100%;
  }

  .card-description {
    margin-bottom: 0.75rem;
  }

  .card-title h3 {
    display: inline-block;
  }

  &--collapsible {

    .card-title {
      display: flex;
      justify-content: space-between;
    }

    .icon--disclosure.is-hidden {
      transform: rotate(90deg);
      transition: transform 500ms;
    }

    .icon--disclosure {
      transition: transform 500ms;
    }
  }

  &.sticky-card {
    position: -webkit-sticky;
    position: sticky;

    &--top-pad {
      top: 5px;
    }
  }
}

.card-header {
  margin-bottom: 0.75rem;
}

.card-footer {
  display: flex;
  justify-content: space-between;

  &--center {
    justify-content: center;
  }

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &--align-end {
    align-items: end;
  }
}

.card-body-wrapper {
  &.hide {
    overflow-y: hidden;

    .card-body {
      height: 0;
    }
  }
}

.ruled-group > div {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.ruled-group > div:last-child {
  border-bottom: none;
}

.card-container {
  &--secondary {
    padding: $base-small-spacing;
    border: $base-border;
    border-radius: $base-border-radius;
    background: white;
  }

  &--darkened {
    padding: $base-small-spacing;
    border: $base-border;
    border-radius: $base-border-radius;
    background: $lightest-gray;
  }
}

@media screen and (min-width: 992px) {
  .card-section-wrapper {
    display: flex;
  }

  .card-section {
    flex: 1;
    margin-right: $base-spacing;
    margin-bottom: 0;

    &:last-child {
      margin: 0;
    }
  }
}

// IE10-11 flexbox hack
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .card .card-section {
    flex: 1 1 auto;
  }
}

collapsible-card {
  .project-card__collapsible--collapsed .card .card-title {
    margin-bottom: 0;
  }

  .collapsible-status-card {
    border-top-width: 5px;
  }
}
