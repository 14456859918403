
.po-invoice-table {
  $border: 1px solid $base-border-color;

  thead {
    border-bottom: $border;
  }

  tfoot {
    text-align: right;
    border-top: $border;
    font-weight: $weight-bold;

    th:first-of-type {
      border-left: none;
    }
    td:last-of-type {
      border-bottom: $border;
    }
    .po-invoice-cards {
      text-align: left;
      font-weight: $weight-regular;

      td {
        padding: $base-tiny-spacing 0 0 0;
        border: 0;
        &:last-of-type {
          background-color: transparent;
        }
      }
    }
  }

  th {
    color: $primary-color;
    text-align: right;
    &.align-left {
      text-align: left;
    }
  }

  th, td {
    border-left: $border;
    border-bottom: none;
    &:first-child {
      border-left: none;
    }
  }

  td {
    &:last-of-type {
      background-color: $lightest-blue;
    }
    &:empty::after {
      content: "\00a0";
    }
  }
}

.po-invoice-form {
  width: auto;
  margin-left: 0.75rem;

  tbody {
    th, td {
      vertical-align: top;
      padding: $base-tiny-spacing;
    }
    th {
      color: $primary-color;
      font-weight: $weight-semibold;
      text-align: right;
    }
  }
}

.po-invoice-copy {
  text-align: center;
  padding: $base-extra-small-spacing;
  font-weight: $weight-semibold;
  height: $environment-indicator-height;
  position: relative;
  width: 100%;
  background-color: #fff080;
  color: $primary-color;
}

.po-invoice-print {
  @media print {
    @supports (display: grid) {
      $column-numbers: 5;

      .columns {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: $base-spacing;
        margin-bottom: 0;

        &--gap-small {
          grid-gap: $base-small-spacing;
        }

        &--gap-none {
          grid-gap: 0rem;
        }

        @for $i from 2 through $column-numbers {
          &--#{$i} {
            grid-template-columns: repeat(#{$i}, 1fr);
          }
        }
      }


      @for $i from 1 through ($column-numbers + 1) {
        .column-start-#{$i} {
          grid-column-start: #{$i};
        }
        .column-end-#{$i} {
          grid-column-end: #{$i};
        }
      }
    }

    tbody tr {
      background-color: transparent !important;
    }
  }
}