.checkbox {
  display: flex;
  align-items: center;

  &:last-child {
    margin: 0;
  }
}

.checkbox-group {
  border: 0;
  background: #fff;
  padding: 10px;
  padding-left: 0;
  overflow: auto;

  label {
    margin: 0 0 0 0.25rem;
  }

  &--inline {
    // 180220 - originally set on fieldsets, now set on div - there is currently a flexbox bug in chrome on fieldsets
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

radio-check {
  display: block;

  label {
    margin-bottom: 0;
    font-weight: normal;
    color: $base-font-color;
  }

  a {
    @include text-link;
  }
}

.radio-check {
  &__check-label-container {
    display: flex;
    align-items: baseline;
  }
}

.checkbox-pills {
  radio-check {
    margin-bottom: 0;

    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }

    label {
      cursor: pointer;
      background-color: white;
      border: 1px solid $light-gray;
      border-radius: $base-xlarge-border-radius;
      transition: $base-transition;
      white-space: nowrap;
      padding: 0.25rem 1rem;
      color: $base-font-color;
      font-weight: normal;
      height: 2rem;
      margin-left: 0px;
    }
  }

  radio-check:has(input[type="checkbox"]:checked) {
    label {
      display: flex;
      align-items: center;
      border: none;
      background-color: $primary-color;
      color: white;
      padding-left: 0.75rem;
      transition: background-color 200ms linear;

      &:hover {
        background-color: $red;

        &:before {
          content: url('../../images/icons/close.svg');
        }
      }
    }

    label:before {
      display: inline-block;
      content: url('../../images/icons/checkmark.svg');
      color: white;
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.25rem;
    }
  }

  &--read-only {
    display: flex;
    align-items: center;
    border: none;
    background-color: $primary-color;
    color: white;
    padding: 0 0.75rem 0 0.5rem;
    transition: background-color 200ms linear;
    overflow: visible;
    min-width: unset;

    &:hover {
      background-color: $red;

      &:before {
        content: url('../../images/icons/close.svg');
      }
    }

    &:before {
      display: inline-block;
      content: url('../../images/icons/checkmark.svg');
      color: white;
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.25rem;
    }

    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }

    &:focus {
      outline: none;
    }
  }

  input[type="checkbox"] {
    // Visually hide the checkbox input but keep it in the DOM so we can tab to it to gain focus
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none;

    &:disabled + label {
      background-color: $lighter-gray;
      border-color: $lighter-gray;
      cursor: not-allowed;
    }
  }
}
