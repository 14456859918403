@keyframes slideIn {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.type-ahead {
  &__group {
    position: relative;
  }

  &__results-container {
    position: absolute;
    top: 100%;
    z-index: 1000;
    width: 100%;
    background: white;
    border: 1px solid $base-border-color;
    border-top: 0;
  }

  &__results-list {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    margin: 0;
    min-height: 0;
    max-height: 13rem;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    box-shadow: $base-box-shadow;
  }

  &__results-list-item {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0.75rem;
    list-style: none;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &-host {
      display: block;
    }

    em {
      font-style: normal;
      font-weight: $weight-semibold;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $secondary-color;
        animation-name: slideIn;
        animation-duration: 150ms;
        animation-fill-mode: forwards;
        animation-timing-function: ease-out;
      }
    }

    .icon {
      color: white;
      margin-right: $base-extra-small-spacing;
    }

    &.active {
      background: $primary-color;
      color: white;
    }

    &.selected {
      cursor: not-allowed;
      pointer-events: none;
      color: $medium-gray;
    }
  }

  &__input-container {
    position: relative;

    .type-ahead__input {
      padding-right: 60px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
