.project-card {

  &__host {
    position: relative;
  }

  &__container {
    height: 100%;
  }

  &__collapsible {
    margin-top: 1rem;

    &__trigger {
      -webkit-appearance: none;
      background-color: transparent;
      position: absolute;
      top: $base-small-spacing;
      right: $base-small-spacing;

      .icon {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        transition: $base-transition;
      }
    }

    .card-title--collapsible > .card-title {
      margin-bottom: 0;
    }

    &--open {
      .project-card__collapsible__trigger {
        .icon {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
      }
    }
  }

  &__project-name {
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-decoration: underline;
    margin-bottom: $base-small-spacing;
    display: inline-block;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 1rem;

    &[aria-expanded="false"] {
      height: 0px;
      overflow: hidden;
    }
  }

  &__content-group {
    &:first-child {
      padding-right: $base-spacing;
    }
  }

  &__institutions {
    margin-right: $base-small-spacing;
  }

  &__status-list {
    margin: 0 0 $base-small-spacing;
    text-align: right;
  }

  &__status-title {
    margin: 0;
    color: $primary-color;
    font-weight: $weight-semibold;
  }

  &__status-text {
    margin: 0;
  }

  &__list-divider {
    margin: $base-small-spacing 0;
  }

  &__list-heading {
    margin-bottom: $base-extra-small-spacing;
  }
}
