dashboard-filter {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  .options-menu button {
    color: $medium-dark-gray;
    background-color: transparent;
    font-size: 0.9rem;
    padding: 0;
    border: none;
    min-width: 0;
    margin-left: 0.2rem;

    .icon {
      color: $medium-dark-gray;
    }

    &--small {
      height: 1.6rem;
    }
  }

  .filter-toggle-button {
    border: none !important;
    color: $medium-dark-gray !important;

    .icon {
      min-width: 20px;
      min-height: 20px;
    }
  }

  .clear-filter-button {
    color: $medium-dark-gray !important;
    margin-left: 1rem;
  }

  .more-filters-button {
    color: $medium-dark-gray !important;
    text-decoration: underline;
    font-size: 0.875rem;
    margin-left: 1rem;
    white-space: nowrap;
    user-select: none;

    &__popup {
      position: absolute;
      width: 450px;
      top: 10px;
      right: 0;
      background-color: white;
      box-shadow: 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%), 0px 11px 15px -7px rgb(0 0 0 / 20%);
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.5rem;
      padding: 20px;

      &-container {
        position: relative;
      }

      button.filter-item {
        margin-bottom: 0;
      }
    }
  }

  .filter-list {
    flex-wrap: wrap;
    height: 2em;
    overflow: hidden;
    margin-left: 0.5rem;
  }
}

.dashboard-filter {
  &__popup {
    position: absolute;
    width: 450px;
    top: 0;
    background-color: white;
    box-shadow: 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%), 0px 11px 15px -7px rgb(0 0 0 / 20%);
    border-radius: 10px;
    display: flex;
    padding: 20px;
    flex-direction: column;
    z-index: 10000;

    collapsible-card:not(:last-child) {
      border-bottom: $base-border;
      margin-bottom: 1rem;
    }

    &-container {
      position: relative;
    }

    &-scroll {
      max-height: 525px;
      overflow: auto;
      padding-left: 0.25rem;
    }
  }
}

.dashboard-config-container {
  display: flex;
  align-items: center;
  border-right: 1px solid #ccc;
  padding-right: 1rem;
  flex-shrink: 0;
}

options-menu-item.delete-dashboard {
  .options-menu__menu__item__link {
    color: $color-error;
  }
}

.dashboard-manager {
  .dashboard-name {
    font-weight: $weight-bold;
  }

  .dashboard-date {
    color: $medium-gray;
    font-size: 0.875rem;
  }
}