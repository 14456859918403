$action-list-item-indicator-size: $site-nav-indicator-size;

.action-list-item {
  background: white;
  border-bottom: $base-border;
  display: block;
  padding: $base-small-spacing;

  &__primary-actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $base-small-spacing;
  }

  &__status-indicator {
    background-color: $teal;
    border-radius: 50%;
    height: $action-list-item-indicator-size;
    margin-right: $base-small-spacing;
    margin-top: 1px;
    width: $action-list-item-indicator-size;

    &--viewed {
      opacity: 0;
    }
  }

  &__checkbox {
    margin-right: 0;
  }

  radio-check {
    margin-bottom: 0;
  }

  .radio-check__check-label-container {
    margin-bottom: 0rem;
  }

  &__title {
    color: $base-font-color;
    font-size: $base-font-size;
    font-weight: $weight-semibold;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;

    .icon {
      color: $medium-gray;
      margin-right: $base-extra-small-spacing;
      width: 1.1rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin-bottom: $base-small-spacing;
    min-width: 7rem;
  }

  &__body-copy {
    margin-bottom: 0;
    word-break: break-word;

    &--truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__secondary-actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__secondary-action-text {
    margin-bottom: 0;
    margin-right: $base-extra-small-spacing;
    min-width: 6.5rem;
  }
}

@media screen and (min-width: 700px) {
  .action-list-item {
    display: flex;
    padding: $base-spacing;

    &__primary-actions {
      justify-content: space-between;
      margin-bottom: 0;
      padding-right: $base-spacing;
    }

    &__content {
      margin-bottom: 0;
      padding-right: $base-spacing;
    }

    &__secondary-actions {
      align-items: center;
      display: flex;
    }
  }
}
