.progress-bar {
  &__bars-container {
    display: flex;
    overflow: hidden;
    height: 2.5rem;
    width: 100%;
    margin-bottom: $base-extra-small-spacing;
    border: $base-border;
    border-radius: $base-border-radius;
    background: $lightest-gray;
  }

  &.bar-focused {
    .progress-bar {
      &__legend-item,
      &__bar {
        opacity: 0.5;
      }
    }
  }

  .progress-bar {
    &__legend-item,
    &__bar {
      &.focused {
        opacity: 1;
      }
    }
  }

  &__legend {
    display: flex;
  }

  &__legend-item {
    display: flex;
    align-items: center;
    margin-right: $base-spacing;

    &:last-child {
      margin-right: 0;
    }
  }

  &__legend-item-label {
    margin-right: $base-tiny-spacing;
  }

  &__legend-item-dot {
    height: 1rem;
    width: 1rem;
    border: $base-border;
    border-radius: 0.5rem;
    margin-right: $base-extra-small-spacing;
  }
}
