.toast {
  align-items: center;
  background: #fff;
  border: 1px solid $light-gray;
  border-top-width: 5px;
  border-radius: 3px;
  box-shadow: $base-box-shadow;
  color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: $base-small-spacing ($base-extra-small-spacing * 4);
  width: auto;
  z-index: 800;

  a {
    @include text-link;
  }

  &__icon {
    background-color: $light-gray;
    border-radius: 50%;
    height: 1.75rem;
    width: 1.75rem;
    margin-top: .5rem;
    margin-bottom: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      cursor: default;
      height: 1.2rem;
      width: 100%;

      svg {
        fill: white;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__body {
    flex-grow: 1;

    p {
      margin-bottom: $base-small-spacing;
      text-align: center;
      color: $base-font-color;
    }

    dl,ul {
      color: $base-font-color;
    }
  }

  .action-button {
    margin-top: $base-small-spacing;
    padding: 0 1.5rem;
  }

  &.toast--success {
    border-top-color: $color-success;

    .toast__icon {
      background-color: $color-success;
    }
  }

  &.toast--error {
    border-top-color: $color-error;

    .toast__icon {
      background-color: $color-error;
    }
  }

  &.toast--warning {
    border-top-color: $color-warning;

    .toast__icon {
      background-color: $color-warning;
    }
  }

  &.toast--info {
    border-top-color: $color-info;

    .toast__icon {
      background-color: $color-info;
    }
  }
}

.page-wrapper {
  .toast {
    position: fixed;
    left: $base-small-spacing;
    right: $base-small-spacing;
    top: $base-small-spacing;
  }
}

@media screen and (min-width: 920px) {
  .page-wrapper {
    .toast {
      top: 2rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .toast {
    flex-direction: row;
    width: fit-content;
    padding: $base-spacing;
    max-width: calc(100% - 4rem);

    &__body {
      max-width: 60rem;

      p {
        text-align: left;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__icon {
      margin-bottom: 0;
      margin-top: 0;
      margin-right: 1rem;

      .icon {
        width: auto;
        min-width: 2rem;
      }
    }

    .action-button {
      margin: 0 0 0 $base-small-spacing;

      &:first-child {
        margin-left: $base-spacing;
      }
    }
  }
}

alert {
  z-index: 1000;
}

// Disallow navigating away to prevent listeners from no longer working
.toast-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}