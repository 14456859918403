.breadcrumbs {
  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    display: inline-block;

    a {
      &:link,
      &:visited {
        color: $medium-gray;
      }

      &:hover {
        color: $medium-dark-gray;
      }
    }

    &::after {
      content: "/";
      margin: 0 $base-extra-small-spacing;
      color: $light-gray;
    }

    &:last-child {
      font-weight: $weight-bold;

      &::after {
        content: "";
      }
    }
  }
}
