a {
  cursor: pointer;
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: lighten($action-color, 30%);
  }
}

.text-link {
  @include text-link;

  &--secondary {
    color: $medium-light-grey !important;
  }
}

.text-link-white {
  @include text-link;
  color: white;
}

.icon-link {
  display: flex;
  align-items: center;

  .icon {
    display: block;
    margin-right: $base-extra-small-spacing;
    min-width: 1.25rem;
  }
}
