.site-nav {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: .9375rem; // 15px
  height: 100%;

  &__container {
    background-color: $site-nav-background-color;
    box-shadow: 2px 0 5px rgba(black, 0.3);
    z-index: 50;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__tree {
    list-style-type: none;
  }

  &__logo {
    height: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
  }

  &__logo-link {
    display: flex;
    justify-content: center;
    padding: $base-spacing 0;
    width: 100%;
    height: 100%;

    &__image {
      transition: opacity 200ms ease;

      &--collapsed {
        opacity: 0;
        position: absolute;
        width: 3rem;
        height: 3rem;
      }
      &--expanded {
        opacity: 1;
        min-width: 10rem;
      }
    }

    &:focus {
      outline-offset: -2px;
    }
  }

  &__item-list {
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
    transition: opacity 200ms ease;

    &--main {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0;
      margin: 0;
    }

    &:not(.site-nav__item-list--main) {
      .site-nav__item-button {
        padding-left: 3.25rem;
      }

      .site-nav__item-content {
        height: 2rem;
      }
    }
  }

  &__item-icon {
    color: inherit;
    margin-right: .5rem;
    margin-left: .5rem;
    transition: opacity 200ms ease;

    &--back {
      height: .8rem;
      width: .8rem;
    }

    &--collapsed {
      opacity: 0;
    }
  }

  &__scroll-container {
    flex-grow: 1;
    flex-shrink: 0;
    overflow-x: hidden;
    position: relative;
  }

  &__scroll {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    width: $secondary-content-width;

    > :first-child {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 100%;
    }
  }

  &--ruled {
    border-top: 1px solid rgba(255,255,255,0.15);
  }

  &--shadowed {
    box-shadow: 0px -2px 3px rgba(0,0,0,0.25);
  }

  &__item {
    position: relative;
    color: $site-nav-text-color;
    margin: 1px .25rem;
    overflow-y: hidden;

    .site-nav__item {
      margin: 0;
      .site-nav__item-link {
        padding-left: 3.25rem;
        .site-nav__item-link {
          padding-left: 3.75rem;
        }
      }
      .site-nav__item {
        .site-nav__item-link {
          padding-left: 4rem;
        }
      }
    }

    &.open {
      overflow-y: visible;

      .site-nav__item-list {
        overflow-y: visible;

        &::before {
          opacity: 1;
        }

        .site-nav__item-content {
          height: 2rem;

          &::before {
            opacity: 1;
          }
        }
      }
    }

    &--with-button {
      padding: $base-small-spacing;

      .action-button {
        width: 100%;
      }
    }

  }

  &__item-content {
    border-radius: $base-border-radius * 2;
    position: relative;
    height: $site-nav-item-height;
    display: flex;
    align-items: center;
    transition: background-color $site-nav-transition-duration ease-out;
    cursor: pointer;

    &:hover, &.group-active {
      background-color: rgba(white, 0.2);
    }
  }

  &__item-notification-badge {
    $badge-size: 1.4rem;
    align-items: center;
    background-color: $site-nav-path-color;
    border-radius: $base-border-radius;
    color: $site-nav-background-color;
    display: flex;
    height: $badge-size;
    line-height: $badge-size;
    justify-content: center;
    min-width: $badge-size;
    margin-right: .5rem;
    padding: 0 0.4rem;
  }

  &__back-button {
    border-top: 1px solid rgba(255,255,255,0.15);
    border-bottom: 1px solid rgba(255,255,255,0.15);

    .site-nav__item-button {
      color: $site-nav-color-active;
    }

    .site-nav__item-content {
      height: 2rem;
    }
  }

  &__section-heading {
    display: block;
    font-size: .875rem;
    font-weight: 600;
    max-height: 100px;
    transition: max-height 100ms ease;

    .site-nav__item-link-text {
      padding-left: 1rem;
    }
  }

  &__list-header {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    padding: 0 $site-nav-right-spacing 0 $site-nav-text-padding;

    &:focus {
      outline-offset: -1px;
    }
  }

  &__list-header-arrow {
    transition: transform $site-nav-transition-duration ease-out;
    color: $site-nav-path-color;

    $arrow-size: 14px;
    height: $arrow-size;
    width: $arrow-size;
    min-height: $arrow-size;
    min-width: $arrow-size;

    &.open {
      transform: rotate(90deg);
    }
  }

  &__list-header-text {
    flex-grow: 1;
    line-height: 1.1;
    transition: opacity 200ms ease;

    &--has-icon {
      padding-left: .25rem;
    }
  }

  &__item-link {
    border-radius: $base-border-radius * 2;
    color: $site-nav-text-color;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    &--has-icon {
      padding-left: .25rem;

      .site-nav__item-link-text {
        padding-left: .25rem;
      }
    }

    &.active {
      background-color: $site-nav-color-active;
      color: $darkest-blue;
      font-weight: 600;

      &:hover, &:focus {
        background-color: $site-nav-color-active;
        color: $darkest-blue;
      }

      .site-nav__item-notification-badge {
        background-color: $red;
        color: white;
      }
    }

    &:hover, &:focus {
      color: $site-nav-text-color;
    }

    &:focus {
      outline-offset: -1px;
    }
  }

  &__item-button {
    color: $site-nav-text-color;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: opacity 200ms ease;

    &:focus {
      outline-offset: -1px;
    }
  }

  &__text-overflow {
    display: block;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    transition: opacity 200ms ease;
  }

  &__item-link-text {
    flex-grow: 1;
    line-height: 1.2;
    transition: opacity 200ms ease;
  }

  &--sudo {
    padding: $site-nav-left-spacing;
    background: $color-warning;
    color: $base-font-color;
    border-bottom: 1px solid $site-nav-border-color;
  }

  &__sudo-user {
    color: $base-font-color;
    background: $color-warning;
    padding: 0.5rem;

    &__text {
      display: block;
      font-weight: 600;
      font-size: .75rem;
      text-transform: uppercase;
    }

    &-logout {
      padding: .5rem;
      background: transparent;
      border: none;

      .icon {
        color: $base-font-color;
      }
    }
  }

  &__grow-section {
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;

    >.site-nav__item {
      >.site-nav__item-list {
        .site-nav__list-header {
          padding-left: 3.25rem;
        }
        .site-nav__item {
          margin: 0 .25rem;
        }
      }
    }

    >.site-nav__item,
    >.site-nav__item.open {
      position: initial;

      >.site-nav__item-list {
        overflow-y: auto;
        position: absolute;
        top: $site-nav-item-height;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &__expand {
    width: $site-nav-expand-button-size;
    min-width: $site-nav-expand-button-size;
    height: $site-nav-expand-button-size;
    border-radius: 50%;
    position: absolute;
    right: calc($site-nav-expand-button-size / -2);
    top: 4rem;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      display: block;
      height: $site-nav-expand-button-icon-size;
      min-width: $site-nav-expand-button-icon-size;
      width: $site-nav-expand-button-icon-size;
    }
  }

  &--collapsed {
    .site-nav__logo-link {
      &__image {
        &--collapsed {
          opacity: 1;
          transition: opacity 200ms ease;
        }
        &--expanded {
          opacity: 0;
          transition: opacity 200ms ease;
        }
      }
    }

    .site-nav__item-list:not(.site-nav__item-list--main),
    .site-nav__list-header-text,
    .site-nav__item-link-text,
    .site-nav__text-overflow {
      opacity: 0;
    }

    .site-nav__back-button .site-nav__text-overflow {
      transition: width 200ms ease;
      width: 0;
    }

    .site-nav__list-header-arrow {
      transform: translateX(-10.75rem);
    }

    .secondary-content {
      width: inherit;
      flex-basis: $site-nav-collapsed-width;
    }

    .site-nav__section-heading {
      max-height: 0;
      transition: max-height 100ms ease;
    }

    .site-nav__item-icon--back {
      margin: 0;
    }

    .site-nav__item-icon--collapsed {
      opacity: 1;
    }
  }

  &__environment {
    text-align: center;
    padding: $base-extra-small-spacing;
    background-color: $teal;
    color: white;
    font-weight: $weight-semibold;
    height: $environment-indicator-height;
    position: fixed;
    width: 100%;

    &--warning {
      background-color: $color-warning;
      color: darken($color-error, 5%);
    }

    &-name {
      text-transform: capitalize;
    }
  }
}
