.pagination-arrows {
  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: $base-spacing;

    .inline-button-group__container {
      margin-left: $base-small-spacing;
    }
  }

  &__numbers {
    display: inline-block;
    margin: 0;
    font-size: 1.4rem;

    span {
      color: $medium-dark-gray;
    }
  }
}