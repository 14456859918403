/* This section is css modification of Angular Material
Year-Month and date picker. Documentation can be found at: https://v5.material.angular.io/components/datepicker/overview
v5.2.5
TODO: When Angular CLI is updated to 6, revisit this section and replace
with the new version
*/

.mat-input {
  &-placeholder-wrapper {
    position: absolute;
    top: 10.225rem;
    margin-left: 0.5rem;
    font-family: $base-font-family;
  }

  &-underline {
    color: transparent;
  }

  &-suffix {
    position: absolute;
    top: 1.8rem;
    right: 0.5rem;
  }

  &-element {
    margin-top: 0 !important;
  }
}

.mat-form-field {
  width: 100%;
  font-family: $base-font-family;
  line-height: inherit !important;

  &-wrapper {
    padding-bottom: 0 !important;
  }

  &-underline {
    position: absolute;
    height: 0 !important;
    visibility: hidden !important;
  }

  &-label {
    top: 1.2rem !important;
    left: 0.8rem !important;
    font-family: $base-font-family !important;
  }

  .mat-focused &-label {
    color: $primary-color !important;
  }

  &-infix {
    border: 0 !important;
    padding: 0 !important;
  }

  &-flex {
    align-items: center !important;
  }

  &-suffix {
    position: absolute !important;
    right: 0.5rem;
  }

  &-underline .mat-form-field-ripple {
    visibility: hidden !important;
  }
}

.mat-calendar {
  width: 100%;
  margin-top: -1.2rem;
  font-family: $base-font-family;

  &-body {
    &-cell-content {
      border-radius: 0 !important;
    }

    &-selected {
      background-color: $primary-color !important;
      color: #fff;
    }

    &-cell {
      border-bottom: 0;
    }

    &-label {
      border-bottom: 0;
      visibility: hidden;
    }
  }

  &-previous-button {
    top: 0 !important;
  }

  &-next-button {
    top: 0 !important;
  }

  &-table-header-divider {
    border-bottom: 0;
    display: none;
  }

  &-header {
    margin-left: 1.25rem;
  }

  &-period-button {
    height: 0;
  }
}

.mat-icon-button {
  color: $primary-color !important;
}

.mat-button {
  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.125rem;
  }

  &-focus-overlay {
    background-color: transparent !important;
  }

  &.mat-primary .mat-button-focus-overlay {
    background-color: transparent;
  }
}

.mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-datepicker-content {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.14),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

input:focus {
  outline: 2px solid $primary-color !important;
  outline-offset: 2px;
}

/* NGX time picker overrides, as part of
 * date-time-picker, time-picker components */

.ngx-timepicker {
  background-color: $base-background-color !important;
  border: 1px solid $light-gray !important;
  border-radius: 0.125rem;
  height: 44px !important;

  &__toggle {
    margin-left: auto;
  }

  &-control {
    &__input {
      font-family: $base-font-family;
    }

    &--active:after {
      background: none !important;
    }
  }

  .period-control__button:after {
    background: $primary-color !important;
  }

  .period-selector__button--active {
    color: $primary-color !important;
  }
}

.ngx-material-timepicker-toggle {
  line-height: 0;
  margin-right: 0.3rem;
  fill: $medium-dark-gray !important;

  &:focus {
    background: white !important;
    outline: $focus-outline !important;
  }
}

.timepicker {
  &__header {
    background-color: $primary-color !important;
  }

  .clock-face__clock-hand {
    background-color: $primary-color !important;

    &::after {
      background-color: $primary-color !important;
    }

    &::before {
      border-color: $primary-color !important;
    }
  }

  .clock-face__number {
    > span.active {
      background-color: $primary-color !important;
    }
  }

  &-dial__time {
    align-items: center !important;
  }

  button:not(.timepicker-dial__item) {
    color: $primary-color !important;
  }
}

/* This section is the modification of ngx-bootstrap date picker
documentation can be found here: https://valor-software.com/ngx-bootstrap/#/datepicker
TODO: when the client side Angular CLI is upgrated to v6.0
replace this component with Angular Material version'
*/

.bs-datepicker {
  position: absolute;
  top: 0;
  left: -9.3125rem;
  background-color: $base-background-color !important;
  border: $light-gray !important;
  border-radius: 0.125rem;
  padding: 1rem;
  width: 23.125rem;
  height: 15.625rem;
}

bs-datepicker-container {
  background-color: $base-background-color !important;
  position: absolute;
  display: block;
  left: 0px;
}

bs-datepicker-container td {
  border-color: transparent;
  border-bottom: 0px solid $light-gray;
}

.bs-datepicker-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_container {
  position: relative;
}

.calendar-input {
  margin: 0;
  padding-left: 1.875rem;
}

.input-icon {
  position: absolute !important;
  bottom: 0.75rem;
  right: 1.125rem;
}

bs-datepicker-navigation-view {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

bs-datepicker-navigation-container {
  position: relative;
  padding: 0;
  margin: 0;
}

.bs-datepicker-head .next {
  border-radius: 1rem;
  height: 1rem;
  min-width: 1rem;
  font-size: 2.25rem;
  pointer-events: auto;
  cursor: pointer;
  border: none;
  vertical-align: bottom;
  height: 1.5em;
  overflow: hidden;
  margin-right: 4rem;
  background-color: $base-background-color;
}

.bs-datepicker-head .previous {
  border-radius: 1rem;
  height: 1rem;
  min-width: 1rem;
  font-size: 2.25rem;
  pointer-events: auto;
  cursor: pointer;
  border: none;
  vertical-align: bottom;
  height: 1.5em;
  overflow: hidden;
  margin-left: 4rem;
  background-color: $base-background-color;
}

.bs-datepicker-head .current {
  min-width: 1rem;
  font-size: 1.5rem;
  pointer-events: auto;
  cursor: pointer;
  border: none;
  vertical-align: bottom;
  height: 1.5em;
  overflow: hidden;
  background-color: $base-background-color;
}

.bs-calendar-container {
  pointer-events: auto;
  cursor: pointer;
}
