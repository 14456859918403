
.content-header {
  background-color: $base-background-color;
  border-bottom: $base-border;
  box-shadow: $base-box-shadow-light;
  z-index: 40;
  width: 100%;

  &__branding {

    height: 4.25rem;
    text-align: center;
    margin: 1rem;

    img {
      height: 100%;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $base-extra-small-spacing $base-spacing;
    min-height: 3rem;

    .notifications-menu-icon {
      position: relative;

      > .notification-status {
        position: absolute;
        top: 0;
        right: 0;
        outline: 1px solid $base-background-color;
      }
    }
  }


}

.title-header {
  border-top: 1px solid $lighter-gray;

  // background-color: $base-background-color;

  .action-button {
    height: 1.7rem;
    font-size: 0.9rem;
  }

  &__bookmarker {
    border: 0;
    min-width: auto;
    overflow: visible;
    padding: 0.3rem;
    margin-left: 6px;
  }

  &__actions {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: $base-extra-small-spacing;

    .btn, .action-button {
      margin-left: $base-small-spacing;
      margin-bottom: $base-extra-small-spacing;
    }
  }

  &__grant-label {
    color: $medium-light-grey;
    font-weight: $weight-semibold;

    > .title-header__grant-text {
      color: $dark-gray;
      font-weight: $weight-regular;
    }
  }

  &__title-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &__titles {
    flex-grow: 1;
    width: 100%;

    &--with-actions {
      padding-right: $base-small-spacing;
    }
  }

  &__title {
    display: block;
    color: $base-font-color;
    font-weight: $weight-semibold;
    margin-bottom: 0;

    &--link > a {
      color: $base-font-color;
      text-decoration: underline;
    }
  }

  &__route-title-container {
    display: flex;
    padding-right: 1rem;
    flex-direction: column;
    justify-content: center;
  }

  &__route-title {
    display: block;
    color: $dark-gray;
    font-size: 0.8rem;
  }

  &__program-title {
    display: block;
    font-weight: $weight-semibold;
    font-size: 0.9rem;
    white-space: nowrap;
  }

  &__status-actions {
    flex-shrink: 0;
    text-align: right;
    padding-left: $base-spacing;
  }

  &__status {
    font-weight: $weight-bold;
    font-size: 0.7rem;
    border-radius: 5px;
    padding: 0 5px;
    background-color: $light-gray;
  }

  &__sub-title {
    margin-bottom: 0;
    color: $medium-dark-gray;
    font-size: 0.8rem;
  }

  &__messages {
    margin-top: $base-small-spacing;
  }
}

.user--logged-out,
.page--password-reset,
.page--login {
  .content-header-v2__branding {
    display: block;
  }
}

.user-header {
  .user-options {
    color: $medium-gray;

    .action-button {
      padding: 1rem 0.5rem;
      height: 1.7rem;
      font-size: 0.9rem;
      border: none;
      color: $medium-gray;
      border-radius: 500px;

      icon {
        color: $medium-gray;
      }


      > .icon {
        height: 0.9rem;
        width: 0.9rem;
        min-height: 12px;
        min-width: 12px;
        color: $medium-gray;
      }
    }
  }

  .user-name {
    color: $base-font-color;
  }
}