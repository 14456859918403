
.pop-up {
  position: absolute;
  background-color: white;
  border-radius: 10px;
  display: flex;
  padding: 20px;
  flex-direction: column;

  border: 1px solid $light-gray;
  box-shadow: $base-box-shadow;

  justify-content: flex-start;
  flex-direction: column;
  padding: $base-small-spacing;
  z-index: 800;
  opacity: 0; // Prevents jumping while calculating position

  width: auto;
  min-width: 20rem;
  max-width: 40rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--close-button {
      background-color: white;
      border-radius: 50%;
      height: 2.5rem;

      .icon {
        height: 20px;
        width: 20px;
        min-width: 20px;
      }
    }
  }

  &--right {
    right: 0;
  }
}

rfa-review-dashboard .pop-up {
  table.reviewers-list {
    width: auto;

    tr, td {
      border: none;
      white-space: nowrap;
    }
  }
}

.pop-up--trigger {
  :not(.badge) {
    icon {
      position: relative;
      transition: $base-transition;
      cursor: pointer;
      width: 15px;
      height: 15px;
      overflow: hidden;

      svg {
        fill: $medium-gray;

        &:hover {
          fill: $medium-dark-gray;
        }
      }
    }
  }
}