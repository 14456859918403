@mixin spinner-color($color) {
  border-top-color: rgba($color, 0.2);
  border-right-color: rgba($color, 0.2);
  border-bottom-color: rgba($color, 0.2);
  border-left-color: $color;
}

@mixin text-link {
  cursor: pointer;
  text-decoration: underline;
}

@mixin clear-button {
  align-items: center;
  background-color: white;
  border: 1px solid $light-gray;
  border-radius: $base-border-radius;
  bottom: 5px;
  color: $base-font-color;
  cursor: pointer;
  display: flex;
  height: 25px;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 5px;
  top: 8px;
  width: 25px;
  z-index: 1;

  &:hover {
    box-shadow: $btn-shadow-light;
  }

  .icon {
    height: 14px;
    width: 14px;
    min-height: 14px;
    min-width: 14px;
    padding: 0;
  }
}

@mixin highlight {
  background-color: lighten($light-blue, 20%);
  border: 0;
  outline: 3px solid $blue;
  outline-offset: -3px;
}

@mixin highlight--positive {
  @include highlight;
  background-color: lighten($color-success, 60%);
  outline-color: $color-success;
}

@mixin highlight--neutral {
  @include highlight;
  background-color: $neutral-background-color;
  outline-color: $gold;
}

@mixin highlight__arrow {
  position: absolute;
  left: .5rem;
  top: calc(50% - 10px);
}
