.media-print {
  display: none !important;
}

@page {
  margin: 0.5in;
  size: 8.5in 11in portrait;
}

@media print {
  html, body {
    margin: 0;
    overflow: auto;
    padding: 0;
    font-size: 9.5pt;
  }

  table {
    page-break-inside: avoid;
  }

  .skip-link,
  .masthead,
  .secondary-content,
  .site-nav__environment,
  .no-print {
    display: none !important;
  }

  .text-button,
  a.no-print-link {
    cursor: default;
    text-decoration: none;
    color: inherit;
  }

  .media-print {
    display: block !important;
  }

  header {
    border-bottom: 1px solid #f3f3f3;
  }

/* typography
----------------------------------------- */
  h1, .h1,
  h2, .h2 {
    page-break-after: avoid;  
  }

  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: #333;
    font-weight: $weight-bold;
    letter-spacing: 0 !important;
    margin: 0 0 .75rem;
    page-break-after: avoid;
  }

  h1, .h1 {
    font-size: 20pt;
  }

  h2, .h2 {
    font-size: 16pt;
  }

  h3, .h3 {
    font-size: 14pt;
  }

  h4, .h4 {
    font-size: 12pt;
  }

  h5, .h5 {
    font-size: 12pt;
  }

  h6, .h6 {
    font-size: 12pt;
  }

  .header-title {
    font-size: 2rem;
    font-weight: $weight-bold;
    margin-bottom: .75rem;
  }

  label {
    color: #333;
    font-weight: $weight-bold;
    letter-spacing: 0 !important;
    margin: 0 0 .5rem;
    page-break-after: avoid;
  }

  p,
  .card .card-section {
    margin-bottom: .5rem; // Consistent margins to save on paper
  }

  .page-wrapper {
    background-color: #fff;
    display: block;
    height: auto;
    width: auto;
    padding-top: 0;
  }

  .main-content {
    display: block;
    height: auto;      
    margin: 0;
    padding: 0;
    width: 100%;
    left: 0;
    overflow: auto;
  }

  .content-body {
    display: block;
    height: auto;
    overflow: auto;
    padding: 0;
    position: static;

    &__container {
      height: auto;  
      margin: 0;
      padding: 0;
      position: static;
    }      
  }

  .workflow-steps__group {
    display: none;
  }

  .card {
    border: 1px solid #999;
    box-shadow: none;
    padding: 1.5rem;
    page-break-after: auto;

    h4 {
      font-weight: $weight-bold;
    }

    .card-section-wrapper {
      display: block;

      .card-section {
        display: block;
      }
    }

    .print-no-break {
      min-height: 4rem;
      page-break-inside: avoid;
    }
  }

  .preserve-flex {
    display: flex !important;
  }

  table,
  .po-table {
    font-size: 10pt;
    margin: 0 0 .5rem;
  }

  th, td {
    padding: 0.25rem 0.5rem;
    white-space: wrap;
  }

  tbody tr:nth-child(even) {
    background-color: #eee;
  }

  div[class^="table-overflow"] {
    min-width: auto;
  }
}

.archival {

  a, button {
    pointer-events: none;
  }

  a {
    text-decoration: none;
  }

  .main-content {
    position: absolute;
  }

  .media-print {
    display: block !important;
  }

  .no-print {
    display: none !important;
  }
}