.highlighted-content {
  background-color: lighten($dark-blue, 70%);
  border-radius: $base-large-border-radius;
  padding: 1rem;
  margin-bottom: $base-small-spacing;

  &:last-child {
    margin-bottom: 0;
  }
}
