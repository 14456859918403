.tree-view {
  margin: 0;
  padding: 0;

  ul {
    list-style: none;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: $base-tiny-spacing;

    &--collapsible {
      padding-right: $base-tiny-spacing;

      &.tree-view__item {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
      }

      &__header {
        align-items: center;
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: space-between;
        width: 100%;
      }

      &__trigger {
        -webkit-appearance: none;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        margin-right: $base-extra-small-spacing;
        padding: 0 $base-extra-small-spacing;

        .icon {
          height: 1.25rem;
          width:1.25rem;
        }
      }

      .tree-view {
        &__item-title {
          flex-grow: 1;
        }

        &__subitems {
          padding-top: $base-extra-small-spacing;
          padding-left: $base-large-spacing * 2;
          width: 100%;

          .tree-view__item {
            align-items: flex-start;
            flex-direction: column;
          }
  
          .tree-view__sub-item {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    &--collapsed {
      .tree-view__item--collapsible__trigger {
        .icon {
          transform: rotate(-90deg);
        }
      }
    }
  }

  & > &__subitems {
    border-bottom: 1px solid $light-gray;
    padding-bottom: $base-small-spacing;
  }

  &__subitems {
    margin: 0 0 $base-small-spacing 0;
    padding: 0 0 0 $base-spacing;

    &__list {
      flex-direction: column;
      margin-bottom: $base-extra-small-spacing;
      padding-left: 0;
      width: 100%;
    }
  }

  & > &__item {
    font-weight: $weight-bold;
  }

  &__item {
    align-items: center;
    transition: $base-transition;

    &:hover,
    &:focus-within {
      background-color: lighten($light-blue, 20%);
    }

    a {
      text-decoration: underline;
    }
  }

  &__item-status {
    border-radius: $base-large-border-radius;
    font-size: 0.8rem;
    font-weight: $weight-bold;
    line-height: 1.2rem;
    height: 1.2rem;
    letter-spacing: 1px;
    padding: 0 $base-extra-small-spacing;
    text-transform: uppercase;
    justify-self: flex-start;

    &--incomplete {
      background-color: lighten($color-error, 52%);
      color: darken($color-error, 5%);
    }

    &--draft {
      background-color: lighten($color-info, 32%);
      color: darken($color-info, 35%);
    }

    &--complete {
      background-color: lighten($color-success, 65%);
      color: darken($color-success, 10%);
    }
  }
}
