@mixin column1 {
  grid-column-start: 1;
  grid-column-end: 2;
}

@mixin column2 {
  grid-column-start: 2;
  grid-column-end: 3;
}

.budget-summary {
  background: white;
  
  &--bordered {
    border: $base-border;
    padding: $base-spacing;
  }

  &__header {
    align-items: center;
    display: flex;
    margin-bottom: $base-extra-small-spacing;
    min-height: 2rem; // prevents layout jumping

    > * {
      margin-right: $base-small-spacing;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &__col1 {

    &__span2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &__row2 {
      @include column1;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &__row3 {
      @include column1;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    &__row4 {
      @include column1;
      grid-row-start: 4;
      grid-row-end: 5;
    }

    &__row5 {
      @include column1;
      grid-row-start: 5;
      grid-row-end: 6;
    }
  }

  &__col2 {

    &__row2 {
      @include column2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &__row3 {
      @include column2;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    &__row4 {
      @include column2;
      grid-row-start: 4;
      grid-row-end: 5;
    }

    &__row5 {
      @include column2;
      grid-row-start: 5;
      grid-row-end: 6;
    }
  }

  &__invalid {
    padding-left: 5px;
    border-left: 3px solid $red;
  }

  &--alt {
    &.budget-summary__invalid {
      border-left: 0;
      padding-left: 0;
    }

    .budget-summary {
      &__status-title {
        font-weight: $weight-regular;
      }
      &__status-text {
        font-weight: $weight-bold;
        margin-left: $base-tiny-spacing;
      }
    }
  }

  &__status-title {
    font-size: 1rem;
    font-weight: $weight-bold;
    line-height: 1.8rem;
    margin: 0;
    color: $primary-color;
    display: inline-block;
  }

  &__status-text {
    margin: 0;
    display: inline;
    font-weight: 200;
    color: #333;
    font-size: 1rem;
  }

  &__alert {
    color: $red;
    margin:0;
    display: inline;
  }
}

@media only screen and (max-width: 1000px) {
  .budget-summary {
    display: block;

    span {
      display: inline-block;
    }

    &__status-title {
      line-height:120%;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0rem;
    }
  }
}
