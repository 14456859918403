.dropdown {
  position: relative;
  margin: 0 0 0.75rem;
  border: $base-border;
  border-radius: $base-border-radius;
  background-color: $base-background-color;
  transition: $base-transition;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:active {
    border-color: $light-gray;
  }

  &::after {
    pointer-events: none;
    content: "\25BE";
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
  }

  .dropdown-select {
    cursor: pointer;
    margin-bottom: 0;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    min-height: 1.35rem;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &--disabled {
    border-color: $light-gray;

    &::after {
      content: none;
    }
    .dropdown-select {
      cursor: not-allowed;
    }
  }

  &.ng-invalid {
    border-left: 5px solid $color-error;
  }

  &.ng-valid[required] {
    border-left: 5px solid $light-gray;
  }
}
