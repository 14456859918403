.file-upload {
  display: flex;
  align-items: center;

  &.text-link {
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__file {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    position: relative;
  }

  &__clear-button {
    @include clear-button;
    position: unset;
    margin-left: 0.5rem;
  }

  &--block {
    display: block;
  }
}