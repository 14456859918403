.search-modal {
  .full-page-modal {
    &__container {
      background-color: $page-background-color;
      display: grid;
      grid-template-columns: min-content 1fr;
      grid-template-rows: min-content;
      grid-gap: 1.5rem;
      padding: $base-large-spacing;
    }

    .search-bar {
      display: flex;
      align-items: center;

      search-input {
        width: 450px;
      }
    }

    .close-button {
      height: 32px;
      width: 32px;
      color: $medium-gray;
      cursor: pointer;
    }
  }

  .search-filters {
    min-width: 300px;

    .filter-section {
      display: flex;
      flex-direction: column;
      max-height: 300px;
      padding: 1rem 0;
      font-size: 0.875rem;

      label {
        font-weight: normal;
      }

      .collapse-button {
        cursor: pointer;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        transition: $base-transition;
      }

      &--open {
        .collapse-button {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
      }

      .filter-item {
        display: flex;

        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }

        input[type=checkbox] {
          & + label {
            color: $base-font-color;
            font-size: 0.875rem;
            font-weight: $weight-regular;
            margin: 0;
          }

          &:focus {
            outline: none !important;
          }
        }
      }
    }

    filter-section {
      display: block;
      border-top: $base-border;
      border-bottom: $base-border;

      &:not(:last-child) {
        border-bottom: none;
      }
    }
  }

  .search-results {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .search-results-list {
    height: 100%;
    overflow: auto;
    position: relative;
  }

  .search-result-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $base-background-color;
    border-radius: $base-large-border-radius;
    border: $base-border;
    padding: 0.9rem;
    margin-top: 0.5rem;

    .result-subtitle {
      padding: 0.25rem 0.5rem;
      margin-top: 0.5rem;
      background-color: $lightish-gray;
      font-size: 0.875rem;
      border-radius: $base-xlarge-border-radius;
      display: inline-block;
    }
  }
}