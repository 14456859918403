.fiscal-reporting-card {

  &__details-list {
    margin: 0 0 $base-small-spacing;
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: $base-spacing;
  }

  &__details-section {
    text-align: right;
  }

  &__details-data {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 680px){
  .fiscal-reporting-card {
    &__details {
      grid-template-columns: 3fr 1fr;
    }
  }
}