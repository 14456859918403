.tab-control {
  &__card {
    background-color: #fff;
    border: $base-border;
    border-radius: 0 $base-border-radius $base-border-radius $base-border-radius;
    padding: $base-spacing;
    margin-top: -1px;
  }

  &__tab-list {
    display: inline-flex;
    border-radius: $base-border-radius $base-border-radius 0 0;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    z-index: 1;
    align-items: flex-end;
  }

  &__item {
    position: relative;
    margin: 0;
    padding: 0;
  }

  .tab-control__label {
    color: $base-font-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 3.4rem;
    background-color: darken($lightest-gray, 5);
    font-weight: $weight-semibold;
    margin: 0;
    line-height: 1.2rem;
    border: 1px solid darken($light-gray, 5);
    text-align: center;
    cursor: pointer;
    padding: 0 $base-extra-small-spacing;
  }

  &__input {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    z-index: -1;

    &:focus {
      & + .tab-control__label {
        outline: 2px solid $focus-outline-color;
        outline-offset: -4px;
      }
    }

    &:checked + .tab-control__label {
      background: white;
      color: $base-font-color;
      border-bottom-color: white;
      height: 3.7rem;

      &:hover {
        background: white;
      }
    }
  }

  &__tab-status-text {
    color: $medium-dark-gray;
    font-weight: normal;

    &--incomplete {
      color: $color-error;
    }
  }

  &__icon {
    height: 1rem;
    width: 1rem;
    margin-top: 0.25rem;
    color: $medium-gray;

    &--incomplete {
      color: $color-error;
    }
  }

  &--condensed {
    .tab-control {
      &__label {
        width: 3rem;
      }
    }
  }
}
