.counter {
  $counter-number-font-size: 32px;
  $counter-note-font-size: $base-font-size;
  display: flex;
  flex-direction: column;
  margin: $base-small-spacing 0 0;

  .counter__title {
    margin: 0;
  }
  .counter__number {
    font-size: $counter-number-font-size;
  }
  .counter__note {
    font-size: $counter-note-font-size;
  }
}
