
.tribute-container {
  position: relative;
  // top: 35%;
  // left: 5%;
  height: auto;
  min-width: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999;
  box-shadow: 0 1px 3px 0 rgba(60,64,67,.3),0 4px 8px 3px rgba(60,64,67,.15);
  border-radius: 5px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    background: white;
  }

  li {
    padding: 5px 10px;
    cursor: pointer;

    .mentions-option {
      &-name {

      }

      &-email {
        font-size: 0.8rem;
        color: $medium-dark-gray;
      }
    }

    span {
      font-weight: bold;
    }

    .highlight, &:hover {
      background: $light-blue;
    }

    .no-match {
      cursor: default;
    }
  }
}

.user-mention {
  color: $primary-color;
  padding: 0.25rem;
  font-size: 0.875rem;
  font-weight: bold;
  user-select: none;
  cursor: default;
}
