@mixin error-state {
  border-left: 4px solid $color-error;

  &:hover {
    border-color: $color-error;
  }
}

@mixin warning-state {
  border-left: 4px solid $color-warning;

  &:hover {
    border-color: $color-warning;
  }
}

.c-field,
.c-textarea,
.dropdown-search-multiple {

  &.error {
    input,
    textarea,
    .chosen-container-multi .chosen-choices {
      @include error-state;
    }
  }

  &.warning {
    input,
    textarea,
    .chosen-container-multi .chosen-choices {
      @include warning-state;
    }
  }
}

.chosen-choices {
  list-style-type: none;
}

// this is separate from because error style
// has to be applied directly to dropdown
.dropdown {
  &.error {
    @include error-state;
  }
  &.warning {
    @include warning-state;
  }
}

// alignment of potential inline error icon or delete icon
.c-field,
.c-textarea,
.dropdown,
.dropdown-search-multiple {

  &__input-container {
    display: flex;
    align-items: center;
    width: 100%;

    .icon--error {
      svg {
        margin: 0 $base-small-spacing $base-small-spacing 0;
        height: 1.25rem;
        width: 1.25rem;
      }
    }

    .icon--delete {
      margin: 0 0 $base-small-spacing $base-small-spacing;
    }
  }
}
