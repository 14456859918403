/*********************************************************
 load order matters!
**********************************************************/

// Base
@import "assets/scss/base/normalize";
@import "assets/scss/base/variables";
@import "assets/scss/base/mixins";
@import "assets/scss/base/animation";
@import "assets/scss/base/buttons";
@import "assets/scss/base/forms";
@import "assets/scss/base/headings";
@import "assets/scss/base/links";
@import "assets/scss/base/lists";
@import "assets/scss/base/media";
@import "assets/scss/base/navigation";
@import "assets/scss/base/tables";
@import "assets/scss/base/typography";
@import "assets/scss/base/layout";

// Components
@import "assets/scss/components/accordion";
@import "assets/scss/components/action-card";
@import "assets/scss/components/action-items";
@import "assets/scss/components/action-list-item";
@import "assets/scss/components/assurance-card";
@import "assets/scss/components/badges";
@import "assets/scss/components/breadcrumbs";
@import "assets/scss/components/budget-summary";
@import "assets/scss/components/calendar";
@import "assets/scss/components/card";
@import "assets/scss/components/certification-card";
@import "assets/scss/components/checkbox";
@import "assets/scss/components/comments";
@import "assets/scss/components/contacts";
@import "assets/scss/components/content-footer";
@import "assets/scss/components/content-header";
@import "assets/scss/components/counter";
@import "assets/scss/components/dashboard";
@import "assets/scss/components/dashboard-filter";
@import "assets/scss/components/datepicker";
@import "assets/scss/components/drag-drop";
@import "assets/scss/components/dropdown";
@import "assets/scss/components/empty-state";
@import "assets/scss/components/error-states";
@import "assets/scss/components/error-stepper";
@import "assets/scss/components/fifo-chart";
@import "assets/scss/components/file-upload";
@import "assets/scss/components/fiscal-reporting-card";
@import "assets/scss/components/full-page-modal";
@import "assets/scss/components/highlighted-content";
@import "assets/scss/components/history";
@import "assets/scss/components/icons";
@import "assets/scss/components/invoice";
@import "assets/scss/components/inline-edit";
@import "assets/scss/components/institution-detail";
@import "assets/scss/components/labeled-text";
@import "assets/scss/components/list-filter";
@import "assets/scss/components/list-row-collapsable";
@import "assets/scss/components/loaders";
@import "assets/scss/components/notifications";
@import "assets/scss/components/options-menu";
@import "assets/scss/components/pagination-arrows";
@import "assets/scss/components/panel";
@import "assets/scss/components/plan-timeline";
@import "assets/scss/components/po-select";
@import "assets/scss/components/po-table";
@import "assets/scss/components/pop-up";
@import "assets/scss/components/profile-card";
@import "assets/scss/components/progress";
@import "assets/scss/components/project-card";
@import "assets/scss/components/score-card";
@import "assets/scss/components/search-input";
@import "assets/scss/components/search-modal";
@import "assets/scss/components/segmented-control";
@import "assets/scss/components/site-nav";
@import "assets/scss/components/status-card";
@import "assets/scss/components/status-item";
@import "assets/scss/components/status-message";
@import "assets/scss/components/system-notification";
@import "assets/scss/components/tab-control";
@import "assets/scss/components/text-icon-pair";
@import "assets/scss/components/toast";
@import "assets/scss/components/tool-tray";
@import "assets/scss/components/tree-view";
@import "assets/scss/components/type-ahead";
@import "assets/scss/components/user-actions";
@import "assets/scss/components/workflow-steps";

// Temporary
@import "assets/scss/base/v2-styles";

// Vendor
@import "assets/scss/vendor/froala";
@import "assets/scss/vendor/tribute";

// Misc
@import "assets/scss/base/print";

*:focus {
  outline: $focus-outline;
  outline-offset: $focus-outline-offset;
}

.contact-meta-item {
  margin-right: 1rem;

  strong {
    color: $primary-color;
  }
}

/* login page stying - cpk - 171120 */

.page--no-sidebar,
.user--logged-out {
  .secondary-content,
  .branding,
  .content-header h1 {
    display: none;
  }

  .card {
    min-width: 300px;
    max-width: 800px;
    margin: 0 auto $base-spacing;

    &--login {
      max-width: 400px;
    }

    &--no-margin {
      margin: 0;
    }
  }
}

.login-links {
  list-style: none;
  margin: 0 0 1rem;
  padding: 0;
}

.card {
  &--login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card-title {
      h3 {
        margin-bottom: $base-spacing;
      }
    }
    .card__footer {
      display: flex;
      justify-content: space-between;
    }
    .card__body {
      .field-group {
        margin-bottom: $base-spacing;
      }
    }
  }

  &--reset-password {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card__footer {
      display: flex;
      justify-content: flex-end;
    }
  }

  &--help-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card__footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 500px) {
  .page--login {
    .card-section-wrapper {
      flex-direction: column;
    }
    .card-section {
      margin-right: 0;
    }
    .card__footer {
      flex-direction: column;
      justify-content: space-between;
    }
    .login-links {
      text-align: center;
    }
    .btn span {
      width: 100%;
      text-align: center;
    }
  }
}

/* ========================
 *     HELPERS
 * ======================== */
@import "assets/scss/base/helpers"; // these must go last to override styles
