app-notifications {
  display: flex;
  height: 100%;

  > div {
    display: flex;
    flex-grow: 1;
    max-width: 100%;
  }

  .dashboard-filter__popup-scroll {
    overflow: visible;
  }

  .actions-row  {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
  }

  .notification-actions .action-button {
    margin-right: 0.5rem;
    padding: 0 0.25rem;
    font-size: 0.875rem;
    height: 1.5rem;
  }
}

.notifications-list {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 33%;
  height: 100%;
  flex-shrink: 0;

  &-header {
    padding: 22px;
    padding-bottom: 0;
    position: relative;
    z-index: 10000;
  }

  &-items {
    flex-grow: 1;
  }

  .actions-row  {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
  }
}

.notification-item {
  display: flex;

  &--clickable {
    cursor: pointer;

    &:hover:not(.notification-item--selected) {
      background-color: $lightest-gray;
    }
  }

  &--selected {
    background-color: $lighter-gray;
  }
}

.notification-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;

  &--empty {
    align-items: center;
    justify-content: center;
  }
}

.notification-body {
  overflow: auto;
  word-break: break-word;
}

.notification-status {
  background-color: $teal;
  border-radius: 50%;
  height: $action-list-item-indicator-size;
  width: $action-list-item-indicator-size;
  margin: 0.5rem;

  &--viewed {
    opacity: 0;
  }
}

.example-viewport {
  height: 200px;
  width: 200px;
  border: 1px solid black;
}

.example-item {
  height: 50px;
}

ul.notifications-menu {
  width: 400px;
  max-width: 400px;

  .options-menu__menu__item__link:hover {
    .action-list-item__title, .subtitle, .notifications-menu-header {
      color: white;
    }
  }

  .notifications-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem
  }

  hr {
    margin: 0;
  }
}