.action-card {
  padding: $base-spacing $base-spacing 0;
  border: $base-border;
  border-radius: $base-border-radius;
  background: white;
  margin-bottom: $base-spacing;

  &__header {
    border-bottom: $base-border;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $base-small-spacing;
  }

  &__heading {
    font-size: 1.1rem;
    margin-bottom: 0;

    &--secondary {
      display: inline-block;
      margin-left: $base-spacing;
      color: $medium-dark-gray;
    }
  }

  &__header-buttons {
    .action-button {
      margin-left: $base-small-spacing;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__body {
    padding: $base-spacing 0;
  }

  &__message {
    text-align: center;

    &-text {
      font-size: $base-font-size;
      margin-bottom: $base-spacing;
    }
  }

  &__footer {
    border-top: $base-border;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $base-small-spacing 0 $base-spacing;
  }

  &__footer-buttons {
    .action-button {
      margin-left: $base-small-spacing;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
