.empty-state {
  background: $neutral-background-color;
  border: 1px solid darken($neutral-background-color, 5);
  color: $dark-gray;
  padding: $base-small-spacing 1rem;
  text-align: left;

  p {
    margin-bottom: $base-small-spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
