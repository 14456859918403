.list-row {

  .icon {
    height: 10px;
    width: 10px;
    margin-right:1rem;
  }
    
  &__section-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start ; 
    height: 3rem;
    vertical-align: middle;
    border-bottom: 1px solid $base-border-color;
    border-top: 1px solid $base-border-color;

    &:hover {
      background-color: $lightest-gray;
    }
  }
    
  &__section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 
    height: 3rem;
    vertical-align: middle;
    background-color: $lightest-gray;
    border-bottom: 1px solid $base-border-color;

    &:hover {
      background-color: $lightest-gray;
    }
  }

  &__small {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    min-width: 100px; 
    border-right: 1px solid $base-border-color;
    margin-left: 2rem;
    height: 100%;
    padding: 1rem 0;
  }

  &__medium {
    display: flex;
    flex: 2;
    justify-content: space-between;
    align-items: center;
    min-width: 150px; 
    border-right: 1px solid $base-border-color;
    margin-left: 2rem;
    height: 100%;
    padding: 1rem 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  &__large {
    display: flex;
    flex: 3;
    justify-content: space-between;
    align-items: center;
    min-width: 200px; 
    border-right: 1px solid $base-border-color;
    margin-left: 2rem;
    height: 100%;
    padding: 1rem 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  &__icon {
    min-width: 30px;
  }

  &__section-main {
    display: grid;  
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2rem 2rem 2rem auto;
    padding: 1rem;
  }

  &__recepients {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin-right: 2rem;
    padding: 1rem 0;
  }

  &__resolved {
    grid-column: 2 / 3;
    grid-row: 1 / 2; 
    margin-right: 2rem;
    padding: 1rem 0;
  }

  &__kind-of-error {
    grid-column: 1 / 3;
    grid-row: 2 / 3; 
    margin-right: 2rem;
    padding: 1rem 0;
  }

  &__subject-type {
    grid-column: 1 / 2;
    grid-row: 3 / 4;  
    margin-right: 2rem;
    padding: 1rem 0;
  }

  &__user-agent {
    grid-column: 2 / 3;
    grid-row: 3 / 4; 
    margin-right: 2rem;
    padding: 1rem 0;
  }

  &__json {
    grid-column: 1 / 3;
    grid-row: 4 / 5; 
    margin: 2rem;
    padding: 2rem;
    background-color: $lightest-gray; 
    border-radius: .5rem;
  }
  
  &__section-collapse {
    border-top: $base-border;
    padding: 0;

    &--collapsed {
      height: 0;
    }

    &--expanded {
      height: auto;
    }
  }
  
  &__section-subtitle {
    display: flex;
    justify-content: space-between;
    margin-block-end: 0rem;
    margin: 0rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: $light-blue;

    h5 {
      margin: 0;
    }
  }
}
  
.list-row-icon--disclosure-down {
  position: relative !important;
  margin: .75rem;
  padding: 0;
  transition: $base-transition;
  cursor: pointer;
  width: 24px;
  height: 24px;
  overflow: hidden;

  svg {
    fill: $medium-gray;

    &:hover {
      fill: $medium-dark-gray;
    }
  }
    
  &.is-open {
    transform: rotate(-90deg);
  }
}

.list-row.po-table__sort-control {
  position: unset;
}