.institution-detail {
  &__item-label {
    color: $primary-color;
    font-size: $base-font-size;
    font-weight: $weight-bold;
    margin: 0;
  }
  &__item {
    margin: 0 0 $base-small-spacing 0;
  }
}
