// Override rounded corners in Froala built-in styles

.fr-box {
  .fr-toolbar,
  .second-toolbar {
    border-radius: 0 !important;
  }
}

.fr-disabled {
  background-color: $lightest-gray;
}

// Prevent non-wrapping "white-space" styles from overflowing page
.fr-element * {
  white-space: normal !important;
}