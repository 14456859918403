.comments-input-field-wrapper {
  position:relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;

  .comments-input-field-content {
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  .comments-input-field-content__action {
    position: absolute;
    left: 0;
    display: none;
    z-index: 0;

    // not using currently, but save as potential part of positioning comment widgets
    &__show {
      display: block;
      z-index: 99;
    }
  }
}

.comment-widget-container {
  // position: absolute;
  // right:'0px';
  // top: '0px';
  margin: 0;
  padding: 0;
}

.comment-widget {
  position: absolute;
  right: 4px;
  display: none;
  z-index: 0;

  // not using currently, but save as potential part of positioning comment widgets
  &.comment-widget__show {
    display: block;
    z-index: 99;
  }
}

.comment-widget-button {
  border-radius: 50% !important;
  height: 36px !important;
  width: 36px !important;
  min-width: 0;
  padding: 0;
  background: white;
  border: thin solid darkgray;

  .icon {
    min-width: 20px;
    min-height: 20px;
  }

}

.comment-element-highlight {
  outline: 2px dashed $gold !important;
  outline-offset: $focus-outline-offset;
}

.comments-comment-form {
  // override froala paragraph style to save space
  p {
    margin-bottom: 0 !important;
  }
}

// .comments-header {
//   &__trigger {
//     position: absolute;
//     top: 0;
//     right: 0;
//     display: flex;
//     align-items: center;
//     background: white;
//     padding: $base-tiny-spacing $base-small-spacing;
//     z-index: 40;
//     box-shadow: $base-box-shadow-light;
//     border-radius: 0 0 0 $base-large-border-radius;
//   }

//   &__container {
//     z-index: 20;
//     display: flex;
//     flex-direction: column;
//     position: absolute;
//     background: white;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     width: $comments-container-width;
//     overflow-y: auto;
//     border-left: $base-border;
//     padding: $base-extra-small-spacing;
//     padding-top: 5px;
//   }

//   &__heading {
//     position: sticky;
//     top: 0;
//     z-index: 2;

//     .comments-title {
//       display: flex;
//       flex-direction: row;

//       .icon {
//         top: 2px;
//       }

//       h4 {
//         font-weight: $weight-semibold;
//         color: $dark-gray;
//       }
//     }

//     .close-button {
//       position: absolute;
//       top: 0;
//       right: 0;
//       padding: 0;
//       cursor: pointer;
//       overflow: hidden;

//       .icon {
//         width: 1.5rem;
//         height: 1.5rem;
//       }

//       svg {
//         fill: $medium-gray;

//         &:hover {
//           fill: $medium-dark-gray;
//         }
//       }
//     }
//   }
// }

.comments {
  &__trigger {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    background: white;
    padding: $base-tiny-spacing $base-small-spacing;
    z-index: -5;
    box-shadow: $base-box-shadow-light;
    border-radius: 0 0 0 $base-large-border-radius;
  }

  &__widget {
    position: relative;
    margin-left: $comments-container-width;
  }

  &__container {
    z-index: 20;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: absolute;
    background-color: white;
    right: 0;
    top: 0;
    bottom: 0;
    width: $comments-container-width;
    // overflow-y: auto;
    border-left: $base-border;
    padding-top: 0;
    // padding-bottom: 600px;
    .fr-box {
      .fr-second-toolbar {
        display: none;
      }

      .fr-element:focus {
        outline: none;
      }

      .fr-placeholder {
        margin-left: $base-tiny-spacing !important;
      }
    }
  }

  &__heading {
    // position: sticky;
    // top: 5px;
    z-index: 2;
    background-color: white;
    padding: $base-extra-small-spacing $base-small-spacing;
    display: flex;
    align-items: center;

    .comments-title {
      display: flex;
      align-items: center;

      &__label {
        font-weight: $weight-semibold;
        margin-left: $base-extra-small-spacing;
      }

      .icon {
        top: 2px;
      }

      h4 {
        font-weight: $weight-semibold;
        color: $dark-gray;
      }

      .title-header__messages {
        margin-top: 0px;
        padding-left: 10px;
      }
    }

    .close-button {
      // position: absolute;
      // top: 0;
      // right: 0;
      display: flex;
      margin: 0;
      padding: 0;
      cursor: pointer;
      overflow: hidden;

      .icon {
        // width: 1.5rem;
        // height: 1.5rem;
      }

      svg {
        fill: $medium-gray;

        &:hover {
          fill: $medium-dark-gray;
        }
      }
    }
  }

  &__body {
    flex: 1 auto;
    position: relative;
    z-index: 1;
    overflow-y: auto;
    padding-bottom: 600px;  // TODO: figure out
    padding: $base-small-spacing;
    padding-top: $base-tiny-spacing;
  }

// }

  .comments-body {
    position: relative;
    z-index: 1;
    scroll-padding-top: 20rem;
    padding-bottom: 1000px;


  }

  .comment-element {
    position: relative;

    &__container {
      // flex-direction: column;
      position: relative;
      // padding: $base-spacing;
      // border: $base-border;
      // border-radius: $base-border-radius;
      // background: white;
      // padding: $base-extra-small-spacing $base-small-spacing;
      // margin-bottom: $base-small-spacing;

      &--elevated {
        box-shadow: $base-box-shadow-light;
      }
    }
  }

  .comment-thread {
    position: relative;

    &__container {
      flex-direction: column;
      position: relative;
      padding: $base-spacing;
      border-radius: $base-large-border-radius;
      background: $lightest-gray;
      padding: $base-font-size $base-font-size;
      margin-bottom: $base-font-size;

      &--selected {
        border: $base-border;
        background-color: $base-background-color;
        outline: none;
        box-shadow: $base-box-shadow-light;
      }

      &--elevated {
        background: white;
        box-shadow: $base-box-shadow-light;
      }

      &--active {
        background: white;
        box-shadow: $base-box-shadow;
      }

      &--resolved {
        // background: $positive-background-color
        background: $lightest-gray;
        > div {
          display: flex;
          align-items: center;
          font-size: 0.8rem;
          margin-bottom: 0.5rem;
        }
      }

      .options-menu {
        &__trigger {
          color: $medium-dark-gray;
          background-color: transparent;
          font-size: 0.9rem;
          padding: 0;
          border: none;
          min-width: 0;
          margin-left: 0.2rem;
          height: 1.25rem;
          width: 1.25rem;

          .icon {
            color: $medium-dark-gray;
          }

          &--small {
            height: 1rem;
          }

          &:focus {
            outline: none;
          }
        }

        &__menu__item__link {
          height: 1.75rem;
        }
      }

      comment-item:not(:first-of-type) > form {
        margin-top: 1rem;
      }
    }

    &__buttons {
      // display: flex;
      // flex-direction: column;
      // // justify-content: flex-end;
      // // display: inline-flex;
      // justify-content: space-between;
      // align-items: right;
      // text-align: right;
      margin: 0;
      padding: $base-extra-small-spacing $base-small-spacing;

      .action-button {
        // height: auto;
        margin-bottom: $base-small-spacing;
        padding: $base-extra-small-spacing $base-small-spacing;

        &:last-of-type {
          margin-bottom: 0;
          margin-left: auto;
        }
      }
    }
  }


  .comment-card {
    position: relative;

    &__container {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      input-field {
        margin-bottom: 1rem;
      }

      &:last-child input-field {
        margin-bottom: 0;
      }

      // &__draft {
      //   // border: thin, dotted, red;
      //   background-color: white;
      // }
    }

    &__buttons {
      margin-bottom: 0px;

      .action-button {
        height: auto;
        margin: 0 $base-extra-small-spacing;
        padding: $base-tiny-spacing $base-font-size;
      }

      // &:last-child {
      //   margin-bottom: 0;
      //   padding-bottom: 0;
      //   margin-left: auto;
      // }
    }

    &__info {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
      text-align: left;

      .resolve-button {
        background-color: transparent;
        min-width: 2rem;
        padding: 0;

        &:focus {
          outline: none;
        }

        .icon {
          height: 24px;
          width: 24px;
        }
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        margin-left: auto;
      }

      .comment-creator {
        font-weight: $weight-semibold;
        &--tagged {
          cursor: pointer;
        }
      }

      .comment-date {
        font-size: 0.8rem;
      }
    }

    &__form {
      margin-top: 0.2rem;

      .div .p {
        margin-bottom: 2px !important;
      }

      // .input-field {
      //   margin-bottom: 2px;
      // }

      input-field:focus {
        outline: none;
      }
    }

    &--elevated {
      box-shadow: $base-box-shadow-light;
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &--darkened {
      background: $lightest-gray;
    }

    &--darkened-more {
      background: darken($lightest-gray, 5%);
    }

    &--nested {
      border: none;
      padding: 0;
    }


  // .card-body-wrapper {
  //   &.hide {
  //     overflow-y: hidden;

  //     .card-body {
  //       height: 0;
  //     }
  //   }
  // }

  // .comment-card-top {
  //   position: relative;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   align-items: right;
  //   text-align: right;

  //   &:last-child {
  //     margin-bottom: 0;
  //     padding-bottom: 0;
  //     // margin-left: auto;
  //   }

  // }

  .ruled-group > div {
    border-bottom: 1px solid #ccc;
    padding-bottom: .25rem;
    margin-bottom: .75rem;
  }

  .ruled-group > div:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;

  }

  .card-container {
    &--secondary {
      padding: $base-small-spacing;
      border: $base-border;
      border-radius: $base-border-radius;
      background: white;
    }

    &--darkened {
      padding: $base-small-spacing;
      border: $base-border;
      border-radius: $base-border-radius;
      background: $lightest-gray;
    }
  }
}

  @media screen and (min-width: 992px) {
    .card-section-wrapper {
      display: flex;
    }

    .card-section {
      flex: 1;
      margin-right: $base-spacing;
      margin-bottom: 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  // IE10-11 flexbox hack
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    .card .card-section {
      flex: 1 1 auto;
    }
  }

  collapsible-card {
    .project-card__collapsible--collapsed .card .card-title {
      margin-bottom: 0;
    }

    .collapsible-status-card {
      border-top-width: 5px;
    }
  }

  &--empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.tab-section.action-button.comments-tab {
  padding: $base-extra-small-spacing $base-small-spacing;

  &:focus {
    outline: none;
  }

  > span {
    margin-left: $base-extra-small-spacing
  }

  .icon {
    top: 2px;
    height: 20px;
    width: 20px;
  }
}