.error-stepper {
  display: flex;

  &__title,
  &__toggle {
    font-size: $base-font-size;
    color: $color-error;
  }

  &__toggle {
    text-decoration: underline;
  }

  &__divider {
    background: $medium-gray;
    margin: 0 $base-small-spacing;
    height: 1rem;
    width: 0.0625rem;
  }

  &__action {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: 0;
    padding: 0;
    margin-right: $base-extra-small-spacing;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
