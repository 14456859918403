.calendar {
  font-size: 0.875rem;
  -webkit-user-select: none;  // disable text selection

  .card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-week-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    > div {
      text-align: right;
      color: $medium-dark-gray;
      font-weight: $weight-regular;
      font-size: 0.8rem;
      padding: 0.25rem 0.5rem;
    }
  }

  &-table {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    border: $base-border;
    flex: 1;

    > div {
      border-right: $base-border;

      &:nth-child(n + 7) {
        border-top: $base-border;
      }

      &:nth-child(7n) {
        border-right: none;
      }
    }
  }

  &__day {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--disabled {
      background-color: $lightest-gray;
      color: $medium-gray;
    }
  }

  &__date {
    display: flex;
    justify-content: flex-end;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 25px;
      height: 25px;

      &.today {
        color: white;
        background-color: $primary-color;
        border-radius: 50%;
      }
    }
  }

  &__event-container {
    cursor: pointer;
    color: $base-font-color;
    padding: 0 5px;
    max-height: 400px;
    overflow-y: auto;
  }

  &__event {
    position: relative;
    border-left: 5px solid transparent;
    border-radius: $base-border-radius;

    &:not(:last-child) {
      margin-bottom: 0.2rem;
    }

    &__more {
      font-weight: $weight-semibold;
    }

    &__background {
      position: absolute;
      top: 0;
      opacity: 15%;
      width: 100%;
      height: 100%;
      border-top-right-radius: $base-border-radius;
      border-bottom-right-radius: $base-border-radius;
    }

    &__title {
      position: relative;
      background-color: transparent;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-left: 5px;
    }
  }

  &__popup {
    position: absolute;
    top: -10000px;
    background-color: white;
    box-shadow: $base-box-shadow-big;
    border-radius: 10px;
    display: flex;
    padding: 20px;
    flex-direction: column;

    &--event {
      width: 350px;

      header {
        display: flex;
        justify-content: space-between;
      }
    }

    .close-button {
      background-color: white;
      border-radius: 50%;
      height: 2.5rem;

      .icon {
        height: 20px;
        width: 20px;
        min-width: 20px;
      }
    }

    &--list {
      width: 250px;

      .close-button {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    label {
      color: $base-font-color;
    }
  }

  &__event-color-icon {
    margin-left: 0.5rem;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
  }

  &__nav-button {
    padding: 0;
    min-width: 2rem;
  }

  &__header-title {
    color: $base-font-color;
    font-weight: $weight-bold;
    font-size: 1.3rem;
  }

 .sync-icon {
    min-height: 24px;
    min-width: 24px;
    height: 24px;
    width: 24px;
  }

  &__third-party-icon {
    height: 40px;
    margin-right: 1.5rem;
  }
}

.calendar__event {
  &:focus-within {
    box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%), 0px 3px 5px -1px rgb(0 0 0 / 20%);
  }

  &:focus {
    outline: none;
  }
}