.certification-card {
  border: $base-border;
  background: white;
  display: flex;
  flex-flow: column;
  height: 100%;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__header {
    padding: $base-small-spacing;
    border-bottom: $base-border;
    background: $lightest-gray;
  }

  &__title {
    color: $primary-color;
    font-size: $base-font-size;
    font-weight: $weight-semibold;
    margin: 0;
  }

  &__main {
    padding: $base-small-spacing;
    flex-grow: 1;
  }

  &__contact-name {
    font-weight: $weight-semibold;
  }

  &__approval-button {
    margin: $base-small-spacing 0.2rem;
  }

  &__approval-status {
    font-weight: $weight-semibold;
    padding: 0.2rem 0;
    position: relative;
    color: white;
    margin: 0;

    &--waiting {
      background-color: $color-warning;
    }

    &--approved {
      background-color: $color-success;
    }

    &--rejected {
      background-color: $color-error;
    }
  }

  &__approval-heading {
    padding: 0 $base-small-spacing;
    margin: $base-small-spacing 0;
    min-height: 2.5rem;
    font-size: $btn-font-size;
    letter-spacing: 1px;
    color: $color-success;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    border-top: $base-border;
    text-align: center;
  }

  &__undo-button {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    height: 100%;
    width: auto;
    color: white;
    line-height: normal;
    background: rgba(black, 0.2);
  }

  &--waiting {
    .certification-card {
      &__approval-status {
        background-color: $color-warning;
      }
    }
  }

  &--approved {
    .certification-card {
      &__approval-status {
        background-color: $color-success;
      }

      &__approval-heading {
        color: $color-success;
      }

      &__undo-button {
        display: block;
      }
    }
  }

  &--rejected {
    .certification-card {
      &__approval-status {
        background-color: $color-error;
      }

      &__approval-heading {
        color: $color-error;
      }
    }
  }

  &--disabled {
    .certification-card {
      &__approval-status {
        background-color: $primary-color;
      }
    }
  }
}

.certification-card-grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  align-items: stretch;
}

@media screen and (min-width: 700px) {
  .certification-card-grid-container {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 3rem;
    grid-column-gap: 3rem;
  }
}

@media screen and (min-width: 1400px) {
  .certification-card-grid-container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 3rem;
    grid-column-gap: 3rem;
  }
}
